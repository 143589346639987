import React, { Component } from 'react';
import { Row, Col,Card ,Button,Container} from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import malaika from "../src/assests/image/FashionDesigner.png"
import Collab from "../src/assests/image/BrandCollab.png"
import socialMediaChallenges from "../src/assests/image/socialMediaChallenge.png";
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './header.js';
import Footer from './footer.js';
import photo from "../src/assests/desktopdummy.jpg"
import { withRouter } from 'react-router-dom';



class FashionContent extends Component {
  constructor(props){
    super(props)
    this.state={
      height:"50px",
      width:"30px",
      fontSize:"125px",
      facebook:false,
      instagram:false,
      youtube:false,
      twitter:false

    }
   
    this.sizereducer=this.sizereducer.bind(this);
    this.sizeHandler=this.sizeHandler.bind(this);
    this.sizeHandleryoutube=this.sizeHandleryoutube.bind(this);
    this.sizereduceryoutube=this.sizereduceryoutube.bind(this);
    this.sizereducerinstagram=this.sizereducerinstagram.bind(this);
    this.instagram=this.instagram.bind(this);
    this.sizereducertwitter=this.sizereducertwitter.bind(this);
    this.twitter=this.twitter.bind(this);
  }
  sizereducertwitter(){
    this.setState({twitter: false});
  }
  twitter(){
    this.setState({twitter: true});
  }
  sizeHandleryoutube(){
    this.setState({youtube: true});
 }
  instagram(){
    this.setState({instagram: true});
  }
  sizereducerinstagram(){
    this.setState({instagram: false});
  }
  sizereduceryoutube(){
    this.setState({youtube: false});

  }

  sizeHandler(){
    this.setState({facebook: true});


  }
  sizereducer(){
    this.setState({
      facebook:false
    });
  }
  handleClcik2=()=>{
 
    this.props.history.push({
      pathname:'/fashionDesigner'
    })
   }
   handleClcik3=()=>{
 
    this.props.history.push({
      pathname:'/BrandCollab'
    })

   }
   handleClciksocialmediaChallenge=()=>{
 
    this.props.history.push({
      pathname:'/socialMediaChallenge'
    })
   }
   
  // <Card.Body>
  //                       <Row>
  //                         <Col><Card style={{ padding: "50px", backgroundColor: "#", marginTop: "10px", border: "none" }} >
  //                           <Card.Body>
  //                             <Row>
  //                               <Col><Card.Img style={{ height: "100%" }} variant="top" src={socialMediaChallenges} onClick={() => this.handleClciksocialmediaChallenge()} style={{ width: "500px", height: "350px" }} />
  //                               </Col>
  //                               <Col><h3 style={{ marginTop: "15%" }}>Social Media Challenges That Entertained Us During</h3>
  //                                 <p style={{ marginBottom: "15%" }}>
  //                                   The global pandemic (COVID-19) brought a loom of uncertainty in life. People were forced to stay indoors. It seemed easy at first but as days passed and time moved on, a dark lining of boredom and frustration began hanging upon the minds. </p>
  //                               </Col>
  //                             </Row>

  //                           </Card.Body>
    render() {
     
        return (
            <div className='homecontent container' style={{border: "none"}}>
              <Row>
              <Col  > 
              <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={Collab}  onClick={() => this.handleClcik3()} style={{ width:"500px",height:"350px"}} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>How To Collaborate With Brands</h3>
      <p style={{marginBottom:"15%"}}>
      According to Seth Godin, a social media influencer doesn’t sell goods and services but creates relations, magic and stories with brands. With the advent of digital platforms, it is not wrong to imply that influencers partnering with the marketing domain of brands are grabbing eye-balls all over.  </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
<Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}}  >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" onClick={() => this.handleClcik2()} style={{ width:"500px",height:"350px"}} src={malaika} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>How To Become A Fashion Designer</h3>
      <p style={{marginBottom:"15%"}}>
      From Coco Chanel to Marc Jacobs and Dior to Bill Cunningham, the fashion industry is filled with iconic and timeless designers who know fashion in and out. According to a fashion designer, fashion is constantly changing. Fashion changes with every event.      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
              <Card style={{border: "none"}} >
  <Card.Body>
      <Row>
          <Col>
          <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={socialMediaChallenges}  onClick={() => this.handleClciksocialmediaChallenge()} style={{ width:"500px",height:"350px"}} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Social Media Challenges That Entertained Us During</h3>
      <p style={{marginBottom:"15%"}}>
      The global pandemic (COVID-19) brought a loom of uncertainty in life. People were forced to stay indoors. It seemed easy at first but as days passed and time moved on, a dark lining of boredom and frustration began hanging upon the minds.
      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>



      
         
          </Col>
      </Row>
  </Card.Body>
</Card>
<Col>
{/* <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={photo} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Beauty</h3>
      <p style={{marginBottom:"15%"}}>
      Hey Guys! I hope you all are doing well and enjoying my travel pictures. I’ve already done a couple of states in the states and Koh Samui this year and Iam planning for some more countries which are untouched by me. I usually spend all my time in exploring rather than chilling while travelling and... 

      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
</Col>
<Col>
<Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={photo} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Beauty</h3>
      <p style={{marginBottom:"15%"}}>
      Hey Guys! I hope you all are doing well and enjoying my travel pictures. I’ve already done a couple of states in the states and Koh Samui this year and Iam planning for some more countries which are untouched by me. I usually spend all my time in exploring rather than chilling while travelling and... 

      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card> */}
</Col>
              </Col>
             </Row>
           </div>
        );
    }
}
export default withRouter(FashionContent);
