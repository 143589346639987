import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import App from "../src/App";
import React, {Component} from 'react';
import Shein from "./Shein";
import contact from "../src/contact";
import FashionTrends from "../src/FashionTrends"
import celebrityQuarantine from "../src/celebrityQuarantine"
import MalaikaSkincare from "../src/Malaikaarora"
import MeghanSkincare from "../src/meghanmarkle"
import fastFashion from "../src/fastFashion"
import socialMediaChallenge from '../src/socialMediaChallenges'
import Fashion from './FashionApp'
import diyTreatment from './diytreatment'
import BeautyApp from "./BeautyApp";
import  BrandCollab from './BrandCollab'
import fashionDesigner from "./fashionDesigner";
import Sesa from "./Sesa";
import Kofol from './Kofol'
import Gummy from "./Gummy";
import keratinHair from "./keratinHair";
class Admin extends Component {
    render() {
        return(
        <div className ="my-app">
            <Router>
                <Route exact path="/" component={App}/>
                <Route exact path="/Shein" component={Shein}/>
                <Route exact path="/Kofol" component={Kofol}/>
                <Route exact path="/Keratin" component={keratinHair}/>
                <Route exact path="/BrandCollab" component={BrandCollab}/>
                <Route exact path="/fashionDesigner" component={fashionDesigner}/>
                <Route exact path="/Sesa" component={Sesa}/>
                <Route exact path="/Hea" component={Gummy}/>
                <Route exact path="/Beauty" component={BeautyApp}/>
                <Route exact path="/dIYtreatment" component={diyTreatment}/>
                <Route exact path="/Fashion" component={Fashion}/>
                <Route exact path="/fashiontrends" component={FashionTrends}/>
                <Route exact path="/celebrityQuarantine" component={celebrityQuarantine}/>
                <Route exact path="/MalaikaSkincare" component={MalaikaSkincare}/>
                <Route exact path="/MeghanSkincare" component={MeghanSkincare}/>
                <Route exact path="/fastFashion" component={fastFashion}/>
                <Route exact path="/contact" component={contact}/>
                <Route exact path="/socialMediaChallenge" component={socialMediaChallenge}/>
            </Router>
        </div>);

    }
}
export default Admin;