import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/article/fashionBanner.png"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';
import Footer from './footer.js';
import Bra from "../src/assests/article/Lounge-Ready It Bra.jpg"
import TheDiscoCollar from "../src/assests/article/The Disco Collar.jpg"
import Crochet from "../src/assests/article/Crochet.jpg"
import HotPants from "../src/assests/article/Hot Pants.jpg"
import HighlighterReel from "../src/assests/article/Highlighter Reel.jpg"
import BermudaPants from "../src/assests/article/Bermuda Pants.jpg"
import ColouredLeather from "../src/assests/article/Coloured Leather.jpg"
import TieredDresses from "../src/assests/article/Tiered Dresses.jpg"
import RetroWallpaperPrints from "../src/assests/article/Retro Wallpaper Prints.jpg"

import HomeContent from './homecontent.js';
import CarouselTitle from './corousalheader.js'



class FashionTrends extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>2020 Fashion Trends That You Must Try</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            Fashion trends that you must try 	
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                        <p>Conquering a fashion trend is finding your lost faith in your personality. You can wear a tight-skinned black dress to a football match and not be the laughing stock. In the end, it is all about wearing your confidence in your skin. A fashion revolution is a challenge; something that inspires the observers to dream about how they would carry the same dress. Mary -Kate Olsen has said that there are no fair game rules in fashion. It is all about what you want to wear and how you want to wear it.</p>

                                        <p>The fact that is most amazing about abiding by a particular fashion trend is that the dress reflects the moment; your moment. Be it your wedding day or your first day on a new job, what you choose to wear reflects what you are as a person.</p>

                                        <p>2020 has not been a promising and positive year. But, we are going to channelize positive energy into you. How? We will tell you about ten fashion trends that you must try this year. The global pandemic has ruined everything. Yet we will help you look not-so-ruined and chic.</p>
                                        <Card.Img variant='top'  src={Bra} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>
                                        <p><b>Lounge-Ready It Bra:</b>  You are living a luxurious life if you can spend your days at home (during quarantine) in your easy-breezy bralette. You can pair it with a high waist ruffle long skirt. If you feel a bit shivery, then, put on a cotton cardigan on top of the bralette. It is the perfect choice for a casual, chilled day at home when you don't feel like dressing up. Try this idea if you want to follow the latest fashion trends for women.</p>
                                        <Card.Img variant='top'  src={TheDiscoCollar} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"20%"}}/>
                                        <p><b>The Disco Collar:</b>  Victoria Beckham, Salvatore Ferragamo and other designers have reawakened disco style. It was dead, but not anymore. Put on a tank top underneath the multicoloured or contrasting jacket, button-down with disco collar. You will stand out in the crowd if you choose this attire as one of the best latest fashion trends in 2020. You can also put on a trench coat and be the eye-candy at a Saturday late-evening party.</p>
                                        <Card.Img variant='top'  src={Crochet} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Crochet:</b> It can be tough to keep up with the latest fashion trends that you want to try. There is so much to choose. One of the best fashion trends of 2020 is wearing crochet. Your grandmother's handmade crochet is an heirloom that will be cherished by every generation. It gives a sentimental value to a beautiful feminine dress. Crochet cardigans are a timeless classic. At the same time, you can wear crochet skirts, dresses, coats etc.</p>
                                        <Card.Img variant='top'  src={HotPants} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Hot Pants:</b>  If there is any model who can convince you to wear hot pants with utmost confidence, then, it is Gigi Hadid. Hot pant is one of the latest fashion trends after it made a strong comeback on 2020 spring runway. We all crave to stay in short shorts at home. But, did you ever think of wearing it outside and look glamorous? You can wear a long sleeve baggy top along with the hot pant. If you feel uncomfortable, then, wear black tights underneath the hot pant.</p>
                                        <Card.Img variant='top'  src={HighlighterReel} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Highlighter Reel:</b>  According to Cosmopolitan, wearing neon green is the latest fashion trend that will be preferred by all 24*7. It is bright and eye-blinding. But, everyone likes to surprise people. Florescent, vibrant yellow, sunny orange etc. do not blend in the crowd and aren't subtle at all. Yet, it checks the entire list an outfit needs to become a trend. Be bold. Don't be a faint-hearted person. Show that you are adventurous by wearing this style confidently in 2020.</p>
                                        <Card.Img variant='top'  src={BermudaPants} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Bermuda Pants:</b>  Can you resist these satin Bermuda pants? We can't. All those who hesitate at the visual representation of them wearing hot pants, do not worry. Bermuda pants have made a triumphant entry into the latest fashion trends for women to save you. Often, people ask whether these Bermuda shorts make them look slightly stunted. Yes, it does. The trick to resolve it is to wear flat footwear and pair it with a button-down jacket. This fashion style has revived the 90's and is all set to enchant us with the sleek, business-woman look.</p>
                                        <Card.Img variant='top'  src={ColouredLeather} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Coloured Leather:</b> The only colour that pops in your mind when we say the leather jacket is black. But, coloured jackets is one of the most stylish fashion trends in 2020. Now, you can choose from burgundy, lemon yellow, red, green, mint and other colours. You can wear a short dress and top it up with a coloured leather jacket. It will be fashionable and comfortable at the same time. Another way to wear colour dyed leather is wearing a short brown leather skirt and pair it with a contrasting leather jacket or a tank top.</p>
                                        <Card.Img variant='top'  src={TieredDresses} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Tiered Dresses:</b>  This light tiered dress is perfect for making a boomerang while twirling. It is lovely and sophisticated. From royal pink to classic black, designers have created a variety of options for you. You can wear tired maxi skirts with a bralette and beat the heat like a fashion pro.</p>
                                        <Card.Img variant='top'  src={RetroWallpaperPrints} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

                                        <p><b>Retro Wallpaper Prints:</b>  Enough with mainstream floral prints. We will now give you the next best thing to floral prints, that is, retro wallpaper geometric patterns. Maybe the eras of '60s and '70s are gone, but, you can keep it alive by wearing retro wallpaper prints. Anything vintage is precious. Anything bohemian is cool and gives a beach-like vibe. Fashion trends make everyone look the same. But that shouldn't stop you from trying one. When we wear something, it is about our style, our comfort mantra not that we are imitating others' looks. Fashion trend proclaims - 'Me Too'. When you wear it, it says - 'only me'. We have given you fashion trends that you must try in 2020 to be more visible in the crowd.</p>
                                    </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default FashionTrends;
