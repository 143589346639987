import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/image/celebrity.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';
import Footer from './footer.js';
import Bra from "../src/assests/article/Lounge-Ready It Bra.jpg"
import TheDiscoCollar from "../src/assests/article/The Disco Collar.jpg"
import Crochet from "../src/assests/article/Crochet.jpg"
import HotPants from "../src/assests/article/Hot Pants.jpg"
import HighlighterReel from "../src/assests/article/Highlighter Reel.jpg"
import BermudaPants from "../src/assests/article/Bermuda Pants.jpg"
import ColouredLeather from "../src/assests/article/Coloured Leather.jpg"
import TieredDresses from "../src/assests/article/Tiered Dresses.jpg"
import RetroWallpaperPrints from "../src/assests/article/Retro Wallpaper Prints.jpg"
import InstagramEmbed from 'react-instagram-embed';
import HomeContent from './homecontent.js';
import CarouselTitle from './corousalheader.js'



class celebrityQuarantine extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>2020 Fashion Trends That You Must Try</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                                Fashion trends that you must try
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                        <p>The world is facing an apocalypse now. It is a strange time when everything is happening online. From work meetings to school/college classes, you are all dependent on Zoom or Google Classroom. In this weird phase, you tend to wear the most comfortable outfits from our closet. At the same time, you are trying to mimic the celebrity quarantine fashion so that you look fabulous yet fuzzy. Often, you will stay in your favorite jumper and shorts - your most favorable work-from-home look. Never before, you could have attended seminars in clothes that you have been wearing for the past 28 hours.</p>

                                        <p>Meanwhile, when everything looks grim, you need some details about quarantine fashion trends to look classy yet comfy despite staying indoors.</p>

                                        <p>From bold dresses to super-cute night pajamas, we have got you covered. Let's find out what our celebrities are wearing at home. Scroll down, read and try to get inspired to not live in the same clothes for three days straight.</p>

                                        <p><b>Wear home-workout outfit like Khloe Kardashian:</b> While you are staying indoors 24*7, you are likely to gain a lot of weight. So, there is a high demand to have a mindset of working out at home; doing planks, yoga, sun-salutation etc. For that, you need the perfect outfit. Wear a sports bra with skinny, leopard print leggings and awaken your dormant fitness goals at home. This, indeed, is one of the best quarantine fashion trends.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B9WyRs0By4w/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p><b>Wear the tie-dye baggy tops like Drew Barrymore:</b> Those who prefer comfort over classy fashion styles yearn to stay at home in baggy white tops with DIY tie-dye prints and uncombed hair looking like a hay-stack. Drew Barrymore has gone well and beyond to make this one of the most popular celebrity fashion trends during the quarantine period.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B_DgHwqDXMF/?utm_source=ig_embed '
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p><b>Wear Mono Colored T-shirt and Pants like Selena Gomez:</b>   We all love to match our tops with pants. We all love to match our low-cut red dress with red strawberry earrings. In short, we like to wear outfits that don't let the observer's eye go astray with too much of colour and designs. That is why wearing the same coloured loungewear is a new trend. Selena Gomez rocks this attire and looks happy while doing so. It is perfect for casual self-care time at home during the lockdown. Celebrity quarantine fashion is for the mass too.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B-YXL2JjmyT/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p><b>Wear Over-sized T-shirts like Taylor Swift:</b>  This outfit idea is a win-win in every way. You can easily borrow your husband's, brother's monochromatic oversized t-shirt if you don't want to invest in at the moment. Just try it out and then you can shout 'Comfortable' from the rooftop. You can pair it up with a denim or any sort of pants if you need to go out and you are not comfortable wearing the over-sized t-shirt as a dress. This attire works perfectly for zoom meetings too (just choose one with collar). Taylor Swift makes it look extremely chic and pleasant at the same time.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B58xdWFDciX/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p><b>Wear Plaids like Camila Cabello:</b>   This outfit will scream 'casual and fun' loudly if you decide to give it a try once. You can wear the plaid and checks shirt like a jacket over a black tank top. Do opt for pastel colors while choosing your plaid button-down shirts. Then match it with jeans or shorts; whatever you feel the most comfortable in. Camila Cabello's look transmits a spring-ish, summer-ish vibe all the way. This is, undoubtedly, one of the most favorite quarantine fashion trends.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B-NhjKHpf8d/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p> <b>Wear Denim like Reese Witherspoon:</b>  It is the summer season. We all love not-so-skinny attires during summer. Few might question why you should wear denim at home. Few will argue that it is not cozy enough to spend your day in. But, look at how Reese has paired classic blue denim with a white summer-ish top. Doesn't it appear to be warm? It is a much-needed breath of breezy air. For example, if a delivery man arrives at your doorstep, you must look presentable. That is what this outfit of the quarantine season does.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B_p1F1JgbFd/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p> <b>Wear Neon Leggings like Demi Lovato:</b>   The self-isolation period is gloomy and you need something to de-stress. The best way to rejuvenate yourself is to practice some yoga, meditation. Also, wearing bright colors might brighten up your mood. Neon leggings are in fashion now. From Jhanvi Kapoor's gym look to Demi Lovato's - wearing neon color pants is a celebrity quarantine fashion trend. Don't hesitate and go splurge on it.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B-kezM9Bmz5/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

                                        <p><b>Wear Bunny-onesies like Katy Perry:</b>  Every once in a while, you must try something that is not usually accepted by society. When that happens and it attracts the mass, then, it becomes a trend. You, then, become the trend-setter. Go ahead and wear this weird bunny-onesie like Katy Perry and reclaim your love for weird celebrity quarantine fashion trends.</p>
                                        <InstagramEmbed
                                            url='https://www.instagram.com/p/B-5dIJRHD_E/?utm_source=ig_embed '
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> There is nothing wrong with wearing sweat-pants and baggy t-shirts all the time at home. But, surprisingly, scientific study has proven that there are benefits of wearing decent and presentable clothes at home (during quarantine). One of the advantages is that it inspires you to be productive. Secondly, it freshens you up in the morning and sets a positive tone for the rest of your day.</p>

                                        <p>Hence, we suggest you pick up any of the celebrity quarantine fashion trends and feel beautiful and comfortable in your skin.</p>
                                    </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default celebrityQuarantine;
