import React, { Component } from 'react';
import { Row, Col,Card ,Button,Container} from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import malaika from "../src/assests/image/malaika.jpeg"
import Meghan from "../src/assests/image/Meghan.jpg"
import socialMediaChallenges from "../src/assests/image/socialMediaChallenge.png";
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './header.js';
import Footer from './footer.js';
import photo from "../src/assests/desktopdummy.jpg"
import { withRouter } from 'react-router-dom';
import diytreatment1 from '../src/assests/image/diytreatment1.jpg';


class HomeContent extends Component {
  constructor(props){
    super(props)
    this.state={
      height:"50px",
      width:"30px",
      fontSize:"125px",
      facebook:false,
      instagram:false,
      youtube:false,
      twitter:false

    }
   
    this.sizereducer=this.sizereducer.bind(this);
    this.sizeHandler=this.sizeHandler.bind(this);
    this.sizeHandleryoutube=this.sizeHandleryoutube.bind(this);
    this.sizereduceryoutube=this.sizereduceryoutube.bind(this);
    this.sizereducerinstagram=this.sizereducerinstagram.bind(this);
    this.instagram=this.instagram.bind(this);
    this.sizereducertwitter=this.sizereducertwitter.bind(this);
    this.twitter=this.twitter.bind(this);
  }
  sizereducertwitter(){
    this.setState({twitter: false});
  }
  twitter(){
    this.setState({twitter: true});
  }
  sizeHandleryoutube(){
    this.setState({youtube: true});
 }
  instagram(){
    this.setState({instagram: true});
  }
  sizereducerinstagram(){
    this.setState({instagram: false});
  }
  sizereduceryoutube(){
    this.setState({youtube: false});

  }

  sizeHandler(){
    this.setState({facebook: true});


  }
  sizereducer(){
    this.setState({
      facebook:false
    });
  }
  handleClcik2=()=>{
 
    this.props.history.push({
      pathname:'/MalaikaSkincare'
    })
   }
   handleClcikDIY=()=>{
 
    this.props.history.push({
      pathname:'/dIYtreatment'
    })
   }

   handleClcik3=()=>{
 
    this.props.history.push({
      pathname:'/MeghanSkincare'
    })

   }
   handleClciksocialmediaChallenge=()=>{
 
    this.props.history.push({
      pathname:'/socialMediaChallenge'
    })
   }
   
  // <Card.Body>
  //                       <Row>
  //                         <Col><Card style={{ padding: "50px", backgroundColor: "#", marginTop: "10px", border: "none" }} >
  //                           <Card.Body>
  //                             <Row>
  //                               <Col><Card.Img style={{ height: "100%" }} variant="top" src={socialMediaChallenges} onClick={() => this.handleClciksocialmediaChallenge()} style={{ width: "500px", height: "350px" }} />
  //                               </Col>
  //                               <Col><h3 style={{ marginTop: "15%" }}>Social Media Challenges That Entertained Us During</h3>
  //                                 <p style={{ marginBottom: "15%" }}>
  //                                   The global pandemic (COVID-19) brought a loom of uncertainty in life. People were forced to stay indoors. It seemed easy at first but as days passed and time moved on, a dark lining of boredom and frustration began hanging upon the minds. </p>
  //                               </Col>
  //                             </Row>

  //                           </Card.Body>
    render() {
     
        return (
            <div className='homecontent container' style={{border: "none"}}>
              <Row>
              <Col  > 
              <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}}  >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" onClick={() => this.handleClcikDIY()} style={{ width:"500px",height:"350px"}} src={diytreatment1} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Best Essential Oils That You Must Have + DIY Treatments</h3>
      <p style={{marginBottom:"15%"}}>
      You may have read quite a lot about tea tree oil.
A lot of people, including me, swear by its ability to treat acne, and that's because it does!
      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
              <Card style={{border: "none"}} >
  <Card.Body>
      <Row>
          <Col>
          <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={socialMediaChallenges}  onClick={() => this.handleClciksocialmediaChallenge()} style={{ width:"500px",height:"350px"}} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Social Media Challenges That Entertained Us During</h3>
      <p style={{marginBottom:"15%"}}>
      The global pandemic (COVID-19) brought a loom of uncertainty in life. People were forced to stay indoors. It seemed easy at first but as days passed and time moved on, a dark lining of boredom and frustration began hanging upon the minds.
      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
<Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={Meghan}  onClick={() => this.handleClcik3()} style={{ width:"500px",height:"350px"}} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Meghan Markle’s Favourite Skincare Product</h3>
      <p style={{marginBottom:"15%"}}>
      Former Suits' actress and the Duchess of Sussex, Meghan Markle has spoken on camera about how she takes care of her skin. She even said that her approach to her skin's ageing is quite different. According to the Duchess, her ability to stay active is her form of meditating therapy.
      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>



      
         
          </Col>
      </Row>
  </Card.Body>
</Card>
<Col>
{/* <Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={photo} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Beauty</h3>
      <p style={{marginBottom:"15%"}}>
      Hey Guys! I hope you all are doing well and enjoying my travel pictures. I’ve already done a couple of states in the states and Koh Samui this year and Iam planning for some more countries which are untouched by me. I usually spend all my time in exploring rather than chilling while travelling and... 

      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card>
</Col>
<Col>
<Card  style={{padding: "50px",backgroundColor: "#",marginTop:"10px",border: "none"}} >
  <Card.Body>
      <Row>
      <Col><Card.Img  style={{height:"100%"}}variant="top" src={photo} />
      </Col>
      <Col><h3 style={{marginTop:"15%"}}>Beauty</h3>
      <p style={{marginBottom:"15%"}}>
      Hey Guys! I hope you all are doing well and enjoying my travel pictures. I’ve already done a couple of states in the states and Koh Samui this year and Iam planning for some more countries which are untouched by me. I usually spend all my time in exploring rather than chilling while travelling and... 

      </p>
      </Col>
      </Row>
  
     
  </Card.Body>
</Card> */}
</Col>
              </Col>
             </Row>
           </div>
        );
    }
}
export default withRouter(HomeContent);
