import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/malaika.jpeg";
import photo from "../src/assests/image/Sesa_Header.png"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';



class Sesa extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Recently I got my hands on Sesa+ Ayurvedic Strong Roots Combo (Oil and Shampoo + Conditioner) both the products are simply amazing.</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>

                            </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p>It's one of the best ayurvedic hair oil and shampoo combo. The hair oil can be easily applied using a comb applicator for easy, direct, and deeper oil penetration to roots. This will help strengthen the roots. It will also help nourish the scalp. I have been using it for over a month now, and I can visibly see the difference in the textures of my hair as well as the overall health of my scalp. The shampoo does the 2 in 1 job, which is the best part for me because I'm too lazy to apply a conditioner and then wait for a couple of minutes and then rinse it. It's like one-and-done products that save a lot of time. It has got an amazing herbal smell as you would normally get in an ayurvedic product. It has also reduced my hair fall. And the dullness of my hair has also reduced. It gives a good shiny and smooth touch to my hair. This is like an overall view and experience I have had so far, but I strongly feel each product needs a dedicated appreciation.</p>
                                    So if I've to suggest 1 product from the oil, it has to be the Sesa+ Ayurvedic Strong Roots Hair Oil:<br />
<br />
The Benefit of Using Sesa Ayurvedic Strong Roots Oil<br />
The first thing is that it is a Certified Ayurvedic hair oil that means you are getting a product that has passed performance tests and quality assurance tests and meets quality standards.<br />
The brand uses a 5000-year-old Ayurvedic Kshir Pak Vidhi to make this oil which is just incredible. The process contains boiling 26 medicinal herbs (like bhringraj, banyan tree extracts, hibiscus) and 6 oils (like coconut, sesame, lemon) in pure cow's milk for 22 hours. And that's what makes it so effective.<br />
It contains No Mineral oil, No Parabens, No Sulphates.<br />
It Strengthens hair follicles and Prevents hair fall and thinning<br />
It supports hair growth and Repairs hair damage<br />
It also prevents premature greying.<br />
<br />
Now let's come to the Sesa+ Ayurvedic Strong Roots Shampoo + Conditioner and the benefits of using it.<br />
It is India's 1st Ayurvedic, Sulphate &amp; Paraben-free Shampoo + Conditioner.<br />
It Contains 26 medicinal herbs similar to what they have in the oil (like bhringraj, banyan tree extracts, hibiscus) and natural conditioning agents for the hair.<br />
It is a sulfate-free formula that cleanses hair very gently<br />
It is also suitable for chemically treated and colored hair<br />
It helps to conditions the hair naturally<br />
Prevents hair fall and breakage and Repairs hair damage<br />
Typically results start showing you after 7-8 regular washes, and significant improvement in hair and scalp condition is seen after 3 months of usage<br />

                                        <p>Easy Steps To Use It:</p>
                                            <p><b>Step 1:</b></p>
                                             <p> Apply the Sesa Ayurvedic Strong Roots Hair Oil and massage it gently on your scalp. Massages help to increase the blood flow to your hair follicles, boosting hair growth and improving the health of your hair. It also ensures that the oil applied reaches every corner of your hair and scalp.</p>
                                             <p><b>Step 2:</b></p>
                                        <p>Next, I like to steam my hair. This also allows the pores on the scalp to open up, better absorption of the product that was previously applied to your scalp.</p>
                                        <p><b>Step 3:</b></p>
                                        <p>Rinse off with Sesa+ Ayurvedic Strong Roots Shampoo + Conditioner for silky smooth hair and lustrous hair. I prefer using lukewarm water while washing your hair to prevent further damage and dryness.</p>

                                    </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default Sesa;
