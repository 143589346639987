import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import photo from '../src/assests/image/diytreatment2.jpg';
import image1 from '../src/assests/image/Image1.jpg';
import image2 from '../src/assests/image/Image2.webp';
import image3 from '../src/assests/image/Image3.jpg';
import image4 from '../src/assests/image/Image4.webp';
import Header from './header.js';



class Kofol extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            {/* <Card.Img variant='top' src={photo} >

                            </Card.Img> */}
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>4 Best Ayurvedic Cough &amp; Cold Products That Actually Works !</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p>
    Every time the weather changes, the number of allergens in the environment
    increases, reaching approximately 200 viruses in the air.
</p>
<p>
    These viruses are primarily responsible for making people sick. The
    following are the most frequent viral fever symptoms:
</p>
<ul>
    <li>
        Fever or feeling feverish/having chills.
    </li>
    <li>
        Cough.
    </li>
    <li>
        Shortness of breath or difficulty breathing.
    </li>
    <li>
        Fatigue (tiredness)
    </li>
    <li>
        Sore throat.
    </li>
    <li>
        Runny or stuffy nose.
    </li>
    <li>
        Muscle pain or body aches.
    </li>
    <li>
        Headache.
    </li>
</ul>
<p>
    Recently I fell sick. If you know me or follow me, you’ll know I’m a true
    believer in the power of Ayurveda. I always try to avoid pills and
    medicine.
</p>
<p>
    When I was sick, I was looking for some ayurvedic brands to help me cure
    fast. And I found Charak Pharma. They have been the Leaders in Ayurveda for
    74 years. Charak products are 100% natural, pass through stringent quality
    checks &amp; are exported to 35-countries. I was very impressed and ordered
    a few of the products that caught my attention. In this blog, I’ll be
    sharing my personal experience using the products.
</p>
<p>
    So the 1st product I urgently needed was cough syrup. Kofol syrup’s
    ayurvedic formulation works on both wet &amp; dry cough. It gives quick
    relief from coughing. Now I always keep this handy in my medicine box.
    Usually, cough syrups make me feel very sluggish and sleepy, but this
    doesn’t make me feel that way. Non-drowsy formulas keep you active during
    the daytime. The best part is it is non-drowsy, sugar-free, and also
    non-alcoholic.
</p>
                                                                        </Card.Text>

                                </Card.Body>



                            </Card>
<Card style={{ "marginTop": "50px", "border": "0" }}>
    <Card.Body>
    <Card.Img variant='top' src={image1} >

</Card.Img>
        <Card.Text style={{ "textAlign": "left" }}>
        <p>
  
</p>
<p>
    Here is the direct link to buy this fantastic product:
</p>
<p>
    Kofol Syrup -
</p>
<p>
    <a href="https://www.charak.com/product/kofol-syrup/" target="_blank">
        https://www.charak.com/product/kofol-syrup/
    </a>
</p>
<p>
    <a href="https://www.vedistry.com/product/kofol-syrup/" target="_blank">
        https://www.vedistry.com/product/kofol-syrup/
      
    </a>
</p>
<p>
    The following mandatory product that I had to get was Kofol Gargle.
</p>
<p>
    This is a ready to use and hassle-free product. You don’t have to dilute
    this in the water, it is very effective and can be used on the go. Kofol
    gargle is made with powerful ayurvedic herbs like the other products that
    help reduce germs &amp; toxins and prevent throat infection. Highly
    beneficial in sore throat. And that is because of the powerful ingredients,
    like Bibitaki, Tulis, Shunthi, and my other helpful herbs.
</p>
<p>
    Because it contains mint, it provides a cooling and soothing effect. It
    also has a minty fresh aftertaste.
</p>
<p>
    It has no bitter aftertaste. That was a huge thumbs up for me.
</p>
        </Card.Text>
    </Card.Body>
</Card>

<Card style={{ "marginTop": "50px", "border": "0" }}>
    <Card.Body>
    <Card.Img variant='top' src={image2} >

</Card.Img>
        <Card.Text style={{ "textAlign": "left" }}>
        <p>
  
</p>
<p>
    Here is the direct link to buy this fantastic product:
</p>
<p>
    Kofol Gargle -
</p>
<p>
    <a href="https://www.charak.com/product/kofol-gargle/" target="_blank">
        https://www.charak.com/product/kofol-gargle/
    </a>
</p>
<p>
    <a href="https://www.vedistry.com/product/kofol-gargle/" target="_blank">
        https://www.vedistry.com/product/kofol-gargle/
      
    </a>
</p>
<p>
    I had too much weakness in my body with all this viral fever, and my
    immunity system also suffered. I love to make herbal teas and Kadhas to
    boost immunity, but I often don’t get the ingredients I need. They are very
    rarely available. Having something in an easy-to-consume form helps to be
    consistent. I got Kofol Immunity Tablets. It has 8 powerful ayurvedic herbs
    like Giloy / Guduchi, Haldi, Triphala Guggul, Shunti, Manjishtha, Chitrak,
    Pippali, and Black pepper, Yashtimadhu. Kofol Immunity Tablets are
    clinically proven to strengthen immunity &amp; improve respiratory health.
    This is hands down my favorite immunity supplement.
</p>
        </Card.Text>
    </Card.Body>
</Card>

<Card style={{ "marginTop": "50px", "border": "0" }}> 
    <Card.Body>
    <Card.Img variant='top' src={image3} >

</Card.Img>
        <Card.Text style={{ "textAlign": "left" }}>
        <p>
  
</p>
<p>
    Here is the direct link to buy this fantastic product:
</p>
<p>
    Kofol Immunity -
</p>
<p>
    <a
        href="https://www.charak.com/product/kofol-immunity-tablets/"
        target="_blank"
    >
        https://www.charak.com/product/kofol-immunity-tablets/
          
    </a>
</p>
<p>
    <a
        href="https://www.vedistry.com/product/kofol-immunity-tablets/"
        target="_blank"
    >
        https://www.vedistry.com/product/kofol-immunity-tablets/
           
    </a>
</p>
<p>
    Last but not least, I took the Kofol Chewable Tablets. I took these tablets
    so that I could carry them to work and have them whenever I felt sore
    throat and any throat discomfort. Must be in the pocket to get immediate
    relief from cold and cough, much better than other medicines. Very
    inexpensive but very effective product. It has menthol and lavang oil that
    not only helps relax the sore throat but also refreshes the breath.
</p>
        </Card.Text>
    </Card.Body>
</Card>

<Card style={{ "marginTop": "50px", "border": "0" }}> 
    <Card.Body>
    <Card.Img variant='top' src={image4} >

</Card.Img>
        <Card.Text style={{ "textAlign": "left" }}>
        <p>
  
</p>
<p>
    Here is the direct link to buy this fantastic product:
</p>
<p>
    Kofol Chewable -
</p>
<p>
    <a
        href="https://www.charak.com/product/kofol-chewable-tablets/"
        target="_blank"
    >
        https://www.charak.com/product/kofol-chewable-tablets/
          
    </a>
</p>
<p>
    <a
        href="https://www.vedistry.com/product/kofol-chewable-tablets/"
        target="_blank"
    >
        https://www.vedistry.com/product/kofol-chewable-tablets/
           
    </a>
</p>
<div>
    <br/>
</div>
        </Card.Text>
    </Card.Body>
</Card>

<Card style={{ "marginTop": "5px", "border": "0" }}>
    <Card.Body>
        <Card.Text style={{ "textAlign": "left" }}>
        <p><strong><span>Do You Know?</span></strong></p>
<p><strong><span>WHY does the change of weather cause illness?</span></strong></p>
<p><span >When the seasons change, most people feel a little under the weather. The temperature change creates an ideal environment for many types of viruses to thrive and spread infectious diseases.</span></p>
<p><span>Human Rhinovirus (HRV) is the most widespread virus, accounting for up to 40% of all colds. They thrive in colder climates, such as spring and winter.&nbsp;</span></p>
<p><span >People with seasonal allergies experience a runny nose and itchy eyes when exposed to pollen, mold, or grass in the summer. Their immune systems become overburdened in response to these allergies, making them more prone to viral attacks.</span></p>
<p><span >But with some minor precautions &amp; lifestyle changes, and including Ayurveda, you can dodge seasonal sickness.</span></p>
<p><span >For all the latest updates and information, don't miss out on the brand's official social media handles.&nbsp;</span></p>
        <p><a href="https://www.charak.com/" target="_blank" >https://www.charak.com/</a>&nbsp;and&nbsp;<a href="https://www.vedistry.com/" target="_blank" >https://www.vedistry.com/</a><br />Instagram Handles - <br /><a href="https://www.instagram.com/vedistryindia/">@vedistryindia&nbsp;</a>&nbsp;
        <a href="https://www.instagram.com/charakpharma/" target="_blank" >@charakpharma&nbsp;</a> <br />Facebook - <br /> <a href="https://www.facebook.com/VedistryIndia" target="_blank">@vedistryindia&nbsp;</a> <a href="https://www.facebook.com/CharakPharma" target="_blank">@charakpharma&nbsp;</a>  </p>

        </Card.Text>
    </Card.Body>
</Card>
{/* <Card style={{ "marginTop": "10px", "border": "0" }}>
    <Card.Body>
        <Card.Text style={{ "textAlign": "left" }}>
       
 </Card.Text>
    </Card.Body>
</Card> */}
                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default Kofol;
