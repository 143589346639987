import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import './App.css';
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import 'bootstrap/dist/css/bootstrap.css';

import image from '../src/assests/blonde-cute-girl-pg.jpg'
import image1 from '../src/assests/girl.jpg'
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import image2 from '../src/assests/maxresdefault.jpg'
import FashionContent from './fashionContent.js'
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      setIsShown: false

    }
  }
  render() {
    const opts = {
      height: '500',
      width: '800',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    return (
      <StickyHeader>
        <div className='header'>
          <div className='container'>
            <div   >
              <Row>
                <Col class="col-md col-sm" sm={10}> 
                <a   style={{ "padding-left": "20%" }}>
                  <img href="https://fashionablenari.com/" src={logo} alt=" Beauty | Makeup Tips, Reviews &amp; Skincare Advice" />
                </a>
                </Col >
                <Col sm={2} class="col-md col-sm" style={{ "padding-top": "15px", "padding-left": "0px" }}>
                  <a href="https://www.facebook.com/Fashionablenari" title="Facebook" style={{ "padding-right": "5px", "color": "#F6C2E0", "font-size": "25px" }}>
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a href="https://www.pinterest.com/bustledotcom/" title="Pinterest" style={{ "padding-right": "5px", "padding-left": "5px", "color": "#F6C2E0", "font-size": "25px" }}><i class="buQ Jym"></i>
                    <i class="fa fa-pinterest"></i>
                  </a>
                  <a href="https://www.instagram.com/fashionablenari/" title="Instagram" style={{ "padding-right": "5px", "padding-left": "5px", "color": "#F6C2E0", "font-size": "25px" }}><i class="buQ eyN"></i>
                    <i class="fa fa-instagram"></i>
                  </a>
                  <a  href="https://www.youtube.com/channel/UC9sQL2xSC95P81MHPUbYoeA?view_as=subscriber" title="Youtube" style={{ "padding-left": "5px", "color": "#F6C2E0", "font-size": "25px" }}>
                    <i class="fa fa-youtube"></i>
                  </a>
                </Col>
              </Row>

            </div>



            <div className='sticky-top '>
              <Row>
                <nav class="navbar navbar-expand ">

                  <div class="navbar-nav ">

                    <Col  class="nav-item col-md col-sm">
                      <a class="nav-link " href="/">HOME</a>
                    </Col>
                    <Col class="nav-item col-md col-sm">
                      <a class="nav-link verticalLine" href="./Fashion">FASHION</a>
                    </Col>
                    <Col class="nav-item col-md col-sm">
                      <a class="nav-link verticalLine" href="./Beauty">BEAUTY</a>
                    </Col>
                    <Col class="nav-item col-md col-sm">
                      <a class="nav-link verticalLine" href="https://www.youtube.com/channel/UC9sQL2xSC95P81MHPUbYoeA?view_as=subscriber">YOUTUBE</a>
                    </Col>
                    <Col class="nav-item col-md col-sm">
                      <a class="nav-link verticalLine" href="./contact">CONTACT</a>
                    </Col>

                  </div>
                </nav>
              </Row>

            </div>


          </div>
        </div>
      </StickyHeader>


    );
  }
}

export default Header;
