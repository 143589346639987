import React, { Component } from 'react';
import { Row, Col, Grid } from 'reactstrap';

import './footer.css';
import pic from "../src/assests/image/test2.png";
import fb1 from "../src/assests/image/fb1.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/image/photo.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';




class Footer extends Component {
  render() {
  
      // const instagram = new Instagram({
      //   clientId: 'ecfa62883f8f4b3680a36560239d1301',
      //   clientSecret: '217246f5c14a499fbbf98e94734d7a67 ',
      //   accessToken: '312153297.1677ed0.c6f88e763e184395879bc70b5f827ff3',
      // });
      
    return (
     <div className="footer">
      <div class="container">
      
         <div class="text-center">
           <div class="pfw-social-footer">
              <a href="https://www.instagram.com/fashionablenari/" >
              <i class="fa fa-instagram"></i>

                  </a>
                   <a href="https://www.facebook.com/fashionablenari" >
                     <i class="fa fa-facebook">
                       </i></a>
                        <a href="https://www.snapchat.com/add/" >
                          <i class="fa fa-snapchat-ghost">
                            </i></a>
                             
                                 </div></div>

                                 <div className='row'>
                                 <div class="col-md-12 footer-menu">
                                   <div class="menu-footer-container">
                                     <ul id="menu-footer" class="footer-links">
                                       <li id="menu-item-31747" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-31747">
                                         <a href="https://hudabeauty.com/huda-kattan/">About Us</a></li>
                                         <li id="menu-item-39255" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-39255">
                                           <a href="https://hudabeauty.com/subscribe-to-our-newsletter/">Newsletter</a></li>
                                           
                                           <li id="menu-item-31746" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-31746">
                                             <a href="https://hudabeauty.com/termscondition/">Terms &amp; Conditions</a></li>
                                             <li id="menu-item-42904" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-42904">
                                               <a href="https://hudabeauty.com/privacy-policy/">Privacy Policy</a></li>
                                               <li id="menu-item-42909" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-42909">
                                                 <a href="https://hudabeauty.com/cookie-policy/">Cookie Policy</a></li></ul></div></div>
                                 </div> </div> 
           </div> 
        
              
               
      
 
);

  }
}

export default Footer;
