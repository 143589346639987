import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';
import pic from "../src/assests/image/malaika.jpeg";
import photo from "../src/assests/image/malaika_arora_23.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';



class Malaikaarora extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Malaika Arora Swears by this Skincare Ingredient</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p> Who doesn't eat potatoes in all its forms? Be it fried, be it mashed, be it grilled - we love to have a potato dish in the menu every day. It is one of the most staple ingredients that you would find at every household, not just at yours.</p>

<p>While we can all hyperventilate about the taste of potatoes, we tend to forget that potato juice is very healthy for our skin. There are salons, of course, to take care of your body. But there is something magical about using natural ingredients to give a natural, radiant glow to your skin.</p>

<p>Potato, as a skincare ingredient, is Malaika Arora's favourite skincare product.</p>

<p>Why is a potato Malaika Arora's favourite skincare ingredient?</p>

<p>According to revelations made by the gorgeous and fit actress in an interview, she mixes potato with papaya and tomatoes. She mixes, blends the three ingredients and freezes them by adding cold water along with it. She puts the blended mixture in an ice tray and then applies the cube on her face. Using an ice cube made with fruits and vegetables transcends a cooling effect and freshens up the person. This mixture turned into ice cubes clears all the pores on the skin and is so much better than a face mask.</p>

<p> Benefits of Potato Juice:  Vitamin C and a brightening enzyme called catecholase are present in potato juice. It helps in curing any blemishes and spots on face. The advantages double up if you blend the potato with lemon juice, tomatoes and a spoonful of yogurt. Potato juice is beneficial for reducing excessive pigmentation.  In the hustle-bustle of life, we often do not get proper sleep and time to relax. So, naturally, we are prone to have dark circles or bags of puffiness under the eyes. Potato has a skin-tightening attribute which is beneficial. Malaika Arora has aged beautifully. She still seems as young as you were/are in your college days. That happened because of her go-to skincare ingredient (potato). Potatoes are rich in antioxidants that can help with your skin if it is prematurely ageing.  With the amount of pollution all over the globe, our skin is very much likely to get affected negatively. Potatoes help with cleansing our skin. It reduces the damages caused by the sun's exposure on our skin. Potatoes are rich in minerals. That helps in stimulating the growth of hair if massaged gently on the hair roots.</p>

<p>Do you want to find out why potato juice should be included in your skincare regime immediately? Scroll to find out the reasons.</p>
<Card.Img variant='top'  src={pic} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p>These are the five ways how the potato is the skincare ingredient that Malaika Arora swears by.</p>

<p>Get rid of any blemish on your skin/get rid of tan on your translucent skin:  There are chances of getting tanned or worse sun-burnt when we go for a holiday to a beach. There might be acne outbursts on the skin on time and again. There might be dark spots or scars left on your skin after you scratched the acne or zit. Potato juice helps in combating all these.  Do you want to know how to make this DIY skincare regime? Grate the peeled potatoes.  Use a strainer to strain the grated potatoes.  Add one teaspoon of honey.  Apply the juice by using cotton to dab on the blemishes and tan. After it gets dry, wash it off with warm water.</p>

<p>Get rid of any persistent wrinkle by wearing this DIY face mask:  Potato juice is going to impressively rejuvenate and boost your skin to make it look taut. If and when mixed with glycerine, potato juice can tighten your skin and remove wrinkles.  Do you want to know how to make this DIY anti-wrinkle mask?  Grate the potatoes.  Mix it with a minimum of three drops of glycerine.  Apply the mixed juice on your face.  Keep it on for around twenty minutes at least.  Wash it off and repeat these steps twice a week.  You will be thrilled how this regime will do wonders at an optimal rate.</p>

<p>Get rid of or lighten the dark circles under your eyes by wearing this DIY mask:  Often we are surrounded by expensive skincare products. Strangely, this solution made from potato is inexpensive and helps to do the same wonder of removing dark circles by lightening them.  Do you want to know how to make this DIY dark circle lightening mask?  Take two spoons of potato juice.  Take two spoons of cucumber juice.  Blend them and apply on your face before going to bed. Wake up in the morning and wash it off with cold water.</p>

<p>Get rid of acne on your skin by wearing this DIY anti-acne scrub:  The source of the outbreak of pimples can't be traceable. They can be caused by diet or lifestyle. This scrub can clear acne without leaving any trace of doing so. No spots, nothing. Do you want to know how to make this DIY scrub?  Take pulps of tomatoes and potatoes; one tablespoon each.  Take Meghan Markle's favourite skincare product - tea tree oil. Mix it with the mixed pulp along with honey and oatmeal powder. Apply the mixture on your face. Keep rubbing it well on your skin for at least 15 minutes.  Finally, wash it off with fresh, cold water.  Oatmeal powder helps in skin exfoliation. Potato and tomato pulp contains antioxidants which help in protecting your skin from germs.</p>

<p>Get rid of worrying about how to stimulate your hair growth by wearing this mask: It doesn't need high-maintenance. Yet, it does encourage hair growth. Do you want to know how to make this hair-growth mask?  Take two raw eggs, strain the juice.  Mix yolk of egg and honey with it. Apply it on hair roots.  Was it off with your shampoo.</p>



<p></p>
                                         </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default Malaikaarora;
