import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';

import photo from "../src/assests/image/socialMediaChallenge.png"
import Header from './header.js';

import InstagramEmbed from 'react-instagram-embed';




class socialMediaChallenges extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Social Media Challenges That Entertained Us During</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            <p> The global pandemic (COVID-19) brought a loom of uncertainty in life. People were forced to stay indoors. It seemed easy at first but as days passed and time moved on, a dark lining of boredom and frustration began hanging upon the minds.</p>

<p>This unprecedented apocalypse made this world more and more dystopic. People lost their jobs, students couldn&rsquo;t attend classes, millions fell prey to the virus while another thousand lives were lost forever. People became unfamiliar with their own lives.</p>

<p>Desperate times demanded creative measures to lessen the epidemic anxiety. People needed to be entertained so social media influencers took the stance and created fun, frolic lockdown challenges over social media. The top ten lockdown challenges which created havoc over the internet are listed below -</p>
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                 
                                    <p> <h3>#DontRushChallenge</h3> </p>
<p>  The fountain of innovative creative spark found its home in this challenge. It was inspired by Young T &amp; Bugsey's song, &quot;Don't Rush&quot;. What did people need to do to participate in this challenge? It is quite simple actually. People were made to pass the make-up brush virtually which would magically upgrade their appearance. Initially, the person's look was natural but it changed when they removed the brush from the face of the camera after having covered it thoroughly. This challenge was transformed into multiple parodies by the content creator, Prajakta Kohli. </p>

<InstagramEmbed
                                            url='https://www.instagram.com/tv/B_CcnrVJUnL/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
<p> <h3>#PatienceChallenge</h3> </p>
<p>  Patience was the need of the time during the worldwide lockdown. People got cranky, stressed and bored with the passage of each day. This challenge was created to inculcate patience among pets and little babies. It was fun and important lesson simultaneously. A camera recorded the reactions of the child or the pet beagle as their treat stayed in front of them but they were strictly instructed to not eat it. The important message that this gave was how people need to use their materialistic resources wisely and not spend it at one go. 
<InstagramEmbed
                                            url='https://www.instagram.com/tv/CAErDMyHnMO/?utm_source=ig_embed '
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
    <p> <h3>#DalgonaCoffeeChallenge</h3> </p>
      If you haven&rsquo;t heard about dalgona coffee then you must have lived your entire quarantine life under a ginormous rock. It started off as a flickering spark on TikTok but, gradually, the fire of aesthetic looking dalgona coffee spread through all social media platforms. Hundred of tutorials were made on the recipe. You haven&rsquo;t tried it yet? What are you waiting for? This was one of the most popular social media challenges that kept our stomach satiated and tongue busy licking the lips.  </p>

<InstagramEmbed
                                            url='https://www.instagram.com/p/CFcTddDnUSl/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#WhoIsMoreLikelyToChallenge</h3> </p>
<p>People tried this challenge with family and friends to find out who is more likely to do the activity that is in the question. For example -  Who between you and your brother is more likely to crib for attention from parents?  Who between you and your best friend is more likely to apologize first after an argument?  There were some quirky and fun questions asked and answered. Television actress, Jannat Zubair Rahmani did the challenge with her brother on YouTube.  
<InstagramEmbed
                                            url='https://www.instagram.com/tv/B_KAmlIDrl1/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#PillowChallenge</h3> </p>
       This was the easiest social media challenge that created a ruckus in the community of content creators on social media. What did you need to do? All you had to do was grab a fluffy, beautiful and comfy pillow, then wrap a belt around it and click the weirdest pictures possible with that designer belt attached pillow wrapped to your waist. This became iconic with the participation of Anne Hathway. 
     <InstagramEmbed
                                            url='https://www.instagram.com/p/B-yobH1hAoa/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#BlanketChallenge</h3> </p>
         All people did at home was to stay in their tank tops and pyjamas all day. So, when this social media challenge began, it was a breath of fresh air for all fashion enthusiasts. Again, it was simple yet eye-catching. Fashionistas needed to use the blanket at home as an outfit that would be stylish and novel. It became very popular as it compelled people to be bold in their vision. 
       
       <InstagramEmbed
                                            url='https://www.instagram.com/p/B_6sCpMH5m0/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
<p> <h3>#BinIsolationOuting</h3> </p>
         Challenge  The backdrop of the challenge was hilarious. Apparently, the trash bag of your home goes outside more than you. So people in Australia started dressing up for carrying the dustbin to the curb. It brought a smile on everyone&rsquo;s faces and lightened up the grave situation that we are still living in. The mundane daily chore became interesting. It was so necessary.  
        <InstagramEmbed
                                            url='https://www.instagram.com/p/B_J_r4fp2Ui/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#TheVogueChallenge</h3> </p>
           Various fashion influencers across the globe took up this challenge and nailed it with their creativity. The budding fashion lovers were given the autonomy to create the cover of Vogue according to them. Amazing initiatives and ideas flooded the social media as people embarked on the lockdown challenge to create a make-up look of their own.
         <InstagramEmbed
                                            url='https://www.instagram.com/p/CBaXWeLJuO5/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#WipeItDown</h3> </p>
             Challenge  Life is filled with trying times. So you should experiment with your look and adhere to the fact that change is the only constant in life. Feeling miserable about your difficulties is optional; you have the power to stomp through it. Quarantine was one of those difficulties and this social media challenge was people&rsquo;s way of stomping away from it. The task was really easy. All you had to do was wipe the mirror and the outfit changes instantly. Lily Singh, a content creator who is widely popular nailed this attire transformation. 
            <InstagramEmbed
                                            url='https://www.instagram.com/p/CFA4S92jo-K/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />
                                        <p> <h3>#GestureChallenge</h3> </p>
                This lockdown challenge broke down all servers literally. Famous personalities like Alia Bhatt, Nick Jonas participated in this challenge which was available as a filter on Instagram. What did you need to do to stay entertained? Listen to the loud background music, look at the emoji gestures on the screen and imitate them. You had to do it right and do it fast. Along with these super-fun challenges that made you brim-full with innocent joy and mirth, there were others that kept people busy. There was a challenge where your kitchen was supposed to have been transformed into a nightclub. There was another challenge where eminent artworks were recreated or replicated.  
              <InstagramEmbed
                                            url='https://www.instagram.com/p/B96PzMcJXwX/?utm_source=ig_embed'
                                            maxWidth={450}
                                            hideCaption={true}
                                            containerTagName='div'
                                            protocol=''
                                            style={{ "marginRight": "25%", "marginLeft": "25%", "marginBottom": "20px", "marginTop": "20px", width: "50%", height: "30%" }}
                                            injectScript
                                            onLoading={() => { }}
                                            onSuccess={() => { }}
                                            onAfterRender={() => { }}
                                            onFailure={() => { }}

                                        />

              The pandemic is deadly and that is why you needed and still need these fun challenges in your lives to de-stress and stay occupied.</p>
                                     </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default socialMediaChallenges;
