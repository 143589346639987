import React, { Component } from 'react';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/image/photo.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/contactUs.jpg';
import Footer from './footer.js'
import Header from './header.js'
import { Card, ImageHeader, CardBody, CardFooter } from "react-simple-card";
import useForm from "react-hook-form";





class contact extends Component {
  render() {
   
    return (
     <div className="App">
       <Header/>
      <div className='container'>
      <div className="containerblog">
      <Card>
    <ImageHeader imageSrc={image} />
    <br/>
    <Form style={{"padding":"20px"}}>
        <FormGroup row >
          <Label for="exampleEmail" sm={2}>Name</Label>
          <Col sm={3}>
            <Input type="email" name="name" id="exampleEmail" placeholder="What's your name?(required)" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="examplePassword" sm={2}>Email</Label>
          <Col sm={3}>
            <Input type="email" name="email" id="examplePassword" placeholder="Email Adress (required)" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="exampleSelect" sm={2}>Subject</Label>
          <Col sm={3}>
            <Input type="text" name="text" id="exampleSelect" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="exampleSelectMulti" sm={2}>Your Message
</Label>
          <Col sm={6}>
            <Input type="select" name="selectMulti" id="exampleSelectMulti" multiple />
          </Col>
        
          <Col sm={{ size: 10, offset: 2 }} style={{"padding":"20px"}}>
            <Button >Submit</Button>
          </Col>
        </FormGroup>
      </Form>
   
  </Card>

      </div>

      </div>
      
      </div>
      
      
    );
  }
}

export default contact;
