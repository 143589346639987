import React, { Component } from 'react';

import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/image/celebrity.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './header.js';
import Footer from './footer.js';
import HomeContent from './homecontent.js';
import CarouselTitle from'./corousalheader.js'
import { withRouter } from 'react-router-dom';



class App extends Component {
  handleClcik=()=>{
    console.log("working")
    this.props.history.push({
      pathname:'/celebrityQuarantine'
    })
   }
  render() {
    const opts = {
      height: '500',
      width: '800',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    return (
     <div className='App'>
       
       <Header/>
       <div >
<div className='Carouselsetting container '>
  
{/* <Carousel>
  <Carousel.Item> */}
    <img
      className="d-block w-100"
      src={photo}
      alt="First slide"
      href="./celebrityQuarantine"
     
      onClick={() => this.handleClcik()}
    />
    <h1 style={{margin:"20px"}}>
    2020 Fashion Trends That You Must Try
   
    </h1>
    {/* <Carousel.Caption>
      <h1>Criss-Cross Back Neck Design</h1>
      <p></p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={pic}
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3></h3>
      <p></p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={pic}
    />

    <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel> */}

</div>
<CarouselTitle />

<HomeContent/>
 
      </div>
      
      </div>
      
      
    );
  }
}

export default withRouter(App);
