import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import photo from '../src/assests/image/diytreatment2.jpg';
import Header from './header.js';



class DIYTreatment extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Best Essential Oils That You Must Have + DIY Treatments</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p>You may have read quite a lot about tea tree oil. A lot of people, including me, swear by its ability to treat acne, and that's because it does! I'm suffering from adult acne, and trust me, it's not the most pleasurable experience. Essential oils are so nice that it has already become popular with various skincare brands, producing and advertising products with it as a star ingredient. The claims are completely true; the use of essential oil for the treatment of acne, and it is often said to help with oily skin. It has anti-inflammatory, antiseptic, and anti-bacterial properties, thus providing relief from itching, redness, and aiding wound healing, all of which are important for helping acne to go away.</p>

<p>Today I'll show you 2 of my favorite and trusted essential oil that I can't live without. Vasu Aromatics Tea Tree Essential Oil &amp; Vasu Aromatics Lavender Essential Oil Vasu Healthcare is renowned for the manufacturing &amp; marketing of Phyto-Pharmaceutical Therapeutics, OTC, Personal Care, and Herbal Supplements since 1980. With 40 years of excellence, their products are available in 40+ countries. The products are 100% Natural and pure.</p>

<p><b>Tea Tree Essential Oil:</b></p>

<p><b>1. DIY Tea Tree Oil Moisturizer</b></p>

<p>Things You'll Need: Your Favorite Face Moisturizer 100% Natural And Pure Tree Essential Oil ( <a href="https://amzn.to/3gHDdVP">Buy Here</a> ) We all have a nice moisturizer that works with our skin type, but if you need some extra help to treat the annoying acne, add some tea tree oil with it. Tea tree oil will immediately transform your daily moisturizer into a strong acne fighter. Take a pea-sized amount of your moisturizer on top of your palm and add a drop of tea tree oil to it. Mix it with a finger and apply it to your face, and massage gently. You can use tapping motion if you have any active acne.</p>

<p><b>2. DIY Toner</b></p>

<p>Things You'll Need: Rose Water 100% Natural And Pure Tea Tree Essential Oil ( <a href="https://amzn.to/3gHDdVP">Buy Here</a>  ) Toning is a very important part of any skincare routine, but it is great for people with oily acne-prone skin, and thus, whatever toner you use creates a huge difference. Your skin can easily respond negatively to any low-quality product, and who wants to make such a minor mistake by selecting the wrong toner and suffering the worst effects, which is problematic acne. Use a natural tea tree oil toner. The tea tree oil I'm using is 100% pure and natural, so it is safe on your skin. To make your tea tree oil-infused toner, in a spray bottle, add 25 ml of rose water and mix in 10-15 drops of tea tree essential oil. You may also add 5 drops of essential lavender oil, but it is optional. Before each use, shake the bottle containing the ingredients. After washing your face, apply it with a cotton ball. Wait for it to get absorbed, and then follow with your usual skincare routine.</p>

<p><b>3. DIY Tea Tree Aloe Gel Acne Healing Face Mask</b></p>

<p>Things You'll Need: Fresh Aloe Vera Gel 100% Natural And Pure Tea Tree Essential Oil ( <a href="https://amzn.to/3gHDdVP">Buy Here</a> ) I don't this aloe vera needs any introduction. It is a well-known magical skincare remedy. Take about 1 tablespoon of the fresh aloe vera gel from the aloe vera leaf in a bowl and add 5-7 drops of tea tree essential oil to it. Mix it well to make a soothing gel. To Use: Wash your face using a mild and gentle moisturizer. If you can steam your face before applying this face, it will become very effective. On your clean skin, apply the face pack and leave it there re-apply as it dries out, do it for 2-3 times, and then you can wash it off using normal water. Apply a moisturizer in the end.</p>

<p><b>Lavender Essential Oil:</b></p>

<p>DIY Skin Lightening Face Moisturizer Things You'll Need: 100% Natural And Pure Lavander Essential Oil ( <a href="https://amzn.to/2Px2iqy">Buy Here</a>  ) Jojoba Oil Your Favorite Face Moisturizer You need a few drops of lavender oil and blend it with a little bit of jojoba oil and moisturizer. You can use it once or twice a day depends on you, and you can expect to see the difference in the tone of your skin. Lavender essential oil helps to minimize inflammation and lightens the skin as well. The decoloration, hyperpigmentation, and dark spots on the skin also fade away with regular use.</p>

<p>Headache And Migraine Reliever Treatment Things You'll Need: 100% Natural And Pure Lavander Essential Oil ( <a href="https://amzn.to/2Px2iqy">Buy Here</a> ) Peppermint Essential Oil Cold Pressed Coconut Oil In a double boiler, warm the coconut oil. Fill your double boiler's bottom half with water and set it over medium-high heat. Place your 1/4 cups of coconut oil in the bowl and add the top half of your double boiler. It will take a few minutes to start melting, but turn it to low once you hear the water's boiling sound. Line up your molds as the coconut oil is melting, so they are ready to use until the coconut oil is completely melted. Next, mix in all of your essential oils 1/2 teaspoon of lavender essential oil and 10-15 drops of peppermint essential oil. Until your coconut oil transforms into a liquid stage. In the glass containers, pour uniformly and let them sit at room temperature to set. Usually, this takes between 30-40 minutes. Over the back of your neck, add a pea-sized quantity. You may also add a small amount to any place where tension is experienced. For headaches, add the balm to your temples and down your hairline, or, if you want more pain relief, layer with one or two drops of essential lavender or peppermint oils.</p>
                                     </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default DIYTreatment;
