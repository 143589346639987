import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';
import Niche from "../src/assests/image/Niche.png";
import Brand from "../src/assests/image/Brand.png";
import Pitch from "../src/assests/image/Pitch.png";
import Structure from "../src/assests/image/Structure.png";
import Execution from "../src/assests/image/Execution.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/image/BrandCollab.png"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';
import Footer from './footer.js';
import Bra from "../src/assests/article/Lounge-Ready It Bra.jpg"
import TheDiscoCollar from "../src/assests/article/The Disco Collar.jpg"
import Crochet from "../src/assests/article/Crochet.jpg"
import HotPants from "../src/assests/article/Hot Pants.jpg"
import HighlighterReel from "../src/assests/article/Highlighter Reel.jpg"
import BermudaPants from "../src/assests/article/Bermuda Pants.jpg"
import ColouredLeather from "../src/assests/article/Coloured Leather.jpg"
import TieredDresses from "../src/assests/article/Tiered Dresses.jpg"
import RetroWallpaperPrints from "../src/assests/article/Retro Wallpaper Prints.jpg"

import HomeContent from './homecontent.js';
import CarouselTitle from './corousalheader.js'



class BrandCollab extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>2020 Fashion Trends That You Must Try</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            Fashion trends that you must try 	
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p><p> According to Seth Godin, a social media influencer doesn't sell goods and services but creates relations, magic and stories with brands. With the advent of digital platforms, it is not wrong to imply that influencers partnering with the marketing domain of brands are grabbing eye-balls all over.</p></p>

<p><p>Your online presence might be equivalent to the number of your followers but your influence over them doesn't adhere to the quantity.</p></p>

<p><p>The relevance of your content, the power of your words to move mountains and your novel thinking will make you a potential mascot of more than enough brands.</p></p>

<p><p>Now, we will give you a step-by-step guideline to figure out the paradox of brand collaborations. The process requires umpteenth efforts. If you want to know how to plan, pitch and execute the brand collaborations, then, keep reading!</p></p>
<Card.Img variant='top'  src={Niche} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p><p>Pick your niche and stick to it - The arena of social media is a huge gallery full of different kind of audience. If you feel like trying your hands on the multiple probable outlets/subjects then you are making a fool's decision. You have to find out which niche makes you comfortable enough to create ripples of change in the community through your ideas. Your profile can be about feminism or books or travel or food blogging or so much more. This helps the concerned brand to get an outline of the targeted audience for their campaign because of your online influence.</p></p>
<Card.Img variant='top'  src={Brand} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p><p>Find the brand with whom you want to work - The only way to keep expanding your online influence is by finding the perfect brand that complements your niche. Draw up a list of potential brands. Do this by following the steps mentioned below: Similar audience Know your worth and value while seeking the appropriate brand Offers complementary services (for example - if you are a bookstagrammer, then, you must incline towards brands which do giveaways) Decent engagement on other posts of the brand Also, you should know if the image of the brand is good enough to be associated with.</p></p>
<Card.Img variant='top'  src={Pitch} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p><p>Pitch your brand collaborations like a pro - There is a difference between being an influencer and being a well-paid influencer. The way you can distinguish between these two terms reflects on how you use your email kit to negotiate the best deal possible. As the number of profiles on social media increases every day, it is very difficult to get the brand you want to work with to notice you. Here, the importance of an effective pitch becomes evident.</p></p>

<p><p>Include these in your pitch to get the result that you want: Subject should be eye-catching. Provide the link to your social media accounts - blogs, instagram, YouTube channel. Mention the kind of engagement and views that your posts usually get. Portfolio matters a lot. So, make sure that you include brands that you have collaborated with. If you are new to the industry, then, mention your monthly or weekly growth rate. Brands like it if you are well-informed about them. Therefore, put in a line or two about the company and how it has impacted you. This will ensure that the brand doesn't think your pitch is a staple email sent to many others. Keep it short, informative and concise. Email pitches usually get scanned by authorities so, please, highlight the bullet points you want to be read during the skim-reading.</p></p>
<Card.Img variant='top'  src={Structure} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p><p>Structure the details of your brand collaboration deal - Just like an offer letter is mandatory while joining a new job, a formal deal agreement with your brand partner is needed. Now, what do you need to include in the structure of the letter? Details about the products that will be marketed. Frequency of the promotional events. The kind of compensation you will receive. Duration of the campaign and the kind of result expected from the campaign.</p></p>
<Card.Img variant='top'  src={Execution} style={{"marginRight":"25%","marginLeft":"25%","marginBottom":"20px","marginTop":"20px", width:"50%",height:"30%"}}/>

<p><p>Execute the promotional post - The preparation needed for execution of the campaign is vital. Check and re-check the relevant measures to be taken before the launch. In case the campaign goes wrong or you don't get the results you wanted, you can always learn from mistakes. Learn and apply them in your next brand collaboration.</p></p>

<p><p>Your pitch will decide if you make or break the deal. So, here are some bonus pointers on how to pitch the right way.</p></p>

<p><p>Finding the right person to email your pitch is not that difficult anymore. Most brands have mentioned their email id in their Instagram bio for subsequent collaborations. So head over to their page and initiate the work relationship. Don't send them a message because it is very likely that your message will get lost in the sea of messages they receive every day. Never, we repeat, never mention the pricing in the first pitch. Get the brand to agree and come on board with your idea and then proceed to negotiate the compensation cost. Doing this beforehand will only scare the brand away. Always include a little bit information on who you are. There are plenty of facebook groups where brands post about hiring a social media influencer for one of their campaigns. Join and pitch to them.</p></p>

<p><p> Tom Fishburne says that the best marketing should not feel like marketing. Your preparation and execution of promotional events for the campaign must apply the saying to its core. There is art in portraying the story behind the product and influencers who indulge in brand collaborations must do justice to that art.</p></p>
                                         </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default BrandCollab;
