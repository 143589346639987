import React, { Component } from 'react';
import { Row, Col, Grid , Card} from 'react-bootstrap';

import './App.css';
import pic from "../src/assests/image/test2.png";
import fb from "../src/assests/image/fb.png";
import logo from "../src/assests/image/FASHIONABLE NARI.png";
import photo from "../src/assests/article/shein-863.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';
import Footer from './footer.js';
import Zara from "../src/assests/article/Zara.jpg"
import Myntra from "../src/assests/article/Myntra.webp"
import Forever21 from "../src/assests/article/Forever 21.jpg"
import hm from "../src/assests/article/h&m.png"
import Koovs from "../src/assests/article/Koovs.png"
import amazon from "../src/assests/article/Amazon.jpg"
import SSS from "../src/assests/article/SSS.jpg"
import HomeContent from './homecontent.js';
import CarouselTitle from'./corousalheader.js'



class Shein extends Component {


  handleClcik=()=>{
    console.log("working")
  }

  render() {
   
    return (
      <div className='App'>
       
       <Header/>
       <div >
<div className='Carouselsetting container '>
  
{/* <Carousel>
  <Carousel.Item> */}
   
   <Card style={{"marginTop":"50px" , "border":"0"}}>
   <Card.Img variant='top'  src={photo} >
   
   </Card.Img>
     <Card.Title style={{"marginTop":"50px" }}>
     <h3 >
    <b>Top 8 SHEIN Alternatives That You Must Try!</b>
    </h3>
     
     </Card.Title>
   
     <Card.Subtitle style={{"marginTop":"20px","textAlign":"left","marginLeft":"20px" }}>
     Shein Alternatives in India
     </Card.Subtitle>
     <Card style={{"border":"0"}}>
       <Card.Body>
       <Card.Text style={{"textAlign":"left"}}>
       <p> Shopping is fun, more so if it is budget-friendly. Shein is a renowned and cherished online platform that has made it possible. It has spread the joy of owning inexpensive yet classy outfits across the globe. It also happens to be one of those 59 Chinese apps that have been recently banned by our Indian Government. The current situation between China and India, that is, their ongoing stand-off is creating ripples of cold-war like tension among citizens of both countries. It has become evident that you, a fashionista, are going to need top shein alternatives in India. Shein was launched in 2008 and ever since it has made the latest fashion trends accessible to the mass.</p>

<p>Now that you have bid adieu to Shein, you might demand to have information about affordable fashion in India to satisfy your inner style diva (even Club Factory and Romwe got banned).</p>

<p>It is high time that we, as a community, start supporting our Government&rsquo;s decisions. That includes not using Chinese apps. We have local businesses and international brands in India that match up to Shein. There are more than sufficient shein alternatives. We don't need to step out of our homes to pick out the perfect home-workout costume. We can do the same by just installing ten online shopping platforms listed below. Buckle up and stop fretting about not having all year long discounts to be availed at Shein.</p>

<p>Here, we give you Shein alternatives in India:</p>

<Card.Img variant='top'  src={Zara} style={{"margin":"20px"}}/>
<p>
1. <a rel="noopener noreferrer" target="_blank" href="https://www.zara.com/in/">Zara:</a>This app needs no introduction at all. As a multinational brand, it is the top-notch alternative to Shein in India. It is not only an efficient counterpart of the Chinese brand Shein but also a recognized trend-setting collection of outfits. The only downside is that it lightens your wallet, unlike Shein. If the price is not an issue for you, then, go and splurge on your next dinner party outfit right at Zara.</p>
<Card.Img variant='top'  src={Forever21} style={{"margin":"20px"}} />
<p>2. <a rel="noopener noreferrer" target="_blank" href="https://www.forever21.in/">Forever 21:</a> This global e-commerce platform has had rough patches. Despite that, it managed to stir up a competition when ran parallel to H&amp;M, Zara and other retail fashion brands. The prices here are affordable. Forever 21 might bear a close resemblance to Shein if we talk about the price. It still needs to emphasize on original designs. Thus, we consider it as one of the top shein alternatives.</p>
<Card.Img variant='top'  src={Myntra} style={{"margin":"20px"}}/>
<p>3. <a rel="noopener noreferrer" target="_blank" href="https://www.myntra.com/">Myntra:</a> It is being run by Flipkart currently. It is a fast-growing fashion portal that offers products from exponentially rated brands like H&amp;M. Myntra owns a collection of various products. Its primary niche is fashion. From not so affluent brands to top brands in the industry, Myntra has something for each one of us. It can easily be a shein alternative.</p>
<Card.Img variant='top'  src={hm} style={{"margin":"20px"}}/>
<p>4. <a rel="noopener noreferrer" target="_blank" href="https://www2.hm.com/en_in/index.html">H&amp;M:</a> If you have this app, then, you won't need to think twice about searching well and beyond for outfits of various colors and sizes. The quality is excellent, the collection is the finest and variety is guaranteed. What else do you need to finally forget Shein?</p>
<Card.Img variant='top'  src={amazon} style={{"margin":"20px"}}/>
<p>5. <a rel="noopener noreferrer" target="_blank" href="https://www.amazon.in/">Amazon:</a> There is no need to go on and on about the benefits of using Amazon.in as your primary shein alternative in India. It provides a plethora of products from every category that exists under the sun. It has well-timed sales, affordable fashion prices, ludicrously attractive discounts and impeccable quality. The brand has evolved over the years. It has become a household name and we hope it continues to thrive like that. You should just go ahead and add items to your cart without any second thoughts.</p>

<p>6. <a rel="noopener noreferrer" target="_blank" href="https://www.ajio.com/">Ajio:</a> It has both national and international brands. This is an edge that most platforms don't have. You will get to explore as much as you can considering the wide range of items available. Also, you will reap the benefits of discounts on a seasonal basis. This Shein alternative in India is a thrilling ride if the customer is ready to shop.</p>
<Card.Img variant='top'  src={Koovs} style={{"margin":"20px"}}/>
<p>7. <a rel="noopener noreferrer" target="_blank" href="https://www.koovs.com/">Koovs:</a> Koovs is known to be the best option for all those who loved Shein&rsquo;s affordable price tag on impressive designer outfits. It is also well-known for the Instagram based fashion campaign called #EverystreetURCatwalk. The brand promotes being comfortable in the clothes that you choose to wear. It is a perfect shein-like brand with designer collaborations happening all the time.</p>
<Card.Img variant='top'  src={SSS} style={{"margin":"20px"}}/>
<p>8. <a rel="noopener noreferrer" target="_blank" href="https://streetstylestore.com/">Street Style Store:</a> It can be considered as a Shein alternative in India but there is a tendency to get disappointed while shopping here. We will not recommend this as our first choice. We can't nullify its importance too. It is time we push this locally owned shop to deliver better quality, better customer service. The way we can do that is by opting to shop there.</p>

<p> Clothes do not mean anything unless you put them on. It doesn't matter if you are not shopping from Shein. All that matters is you wear what you want to wear with the same amount of confidence.</p>

     </Card.Text>

       </Card.Body>

     </Card>
     

    
   </Card>
    


</div>
{/* <CarouselTitle /> */}


 
      </div>
     
      </div>
      
      
    );
  }
}

export default Shein;
