import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';

import './App.css';

import photo from "../src/assests/image/FashionDesigner.png"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';




class fashionDesigner extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>2020 Fashion Trends That You Must Try</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            Fashion trends that you must try 	
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p>From Coco Chanel to Marc Jacobs and Dior to Bill Cunningham, the fashion industry is filled with iconic and timeless designers who know fashion in and out. According to a fashion designer, fashion is constantly changing. Fashion changes with every event. The eminent Italian fashion designer and owner of an international fashion house, Gianni Versace says that fashion is not about blending into the trends, rather, it is about expressing who you are and how you live through what you choose to wear.</p>
<p><strong><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></strong></p>
<p dir="ltr"><strong><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">What is fashion designing?&nbsp;</span></strong></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">It is an art form that inhabits the creation of clothes and other supplemental accessories which are a part of a lifestyle. A fashion designer applies aestheticism into clothing while catering to the changing tastes of consumers. The articulation depends on deep-rooted research. In modern fashion designing, there are three types of clothes made -</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ul>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Haute Couture (clothes are customized according to the specifications given by the customer)</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Ready to wear (these clothes are not dedicated to a particular customer. The fashion houses present these clothes during Fashion Week every season)</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Mass Market (these clothes are cheaper than the above two categories as low-quality fabrics are used. Usually mass-market clothes are different versions of the original trends created by reputed fashion houses)</span></p>
</li>
</ul>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Are you fascinated by this arena of noteworthy and groundbreaking fashion trends?&nbsp;</span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Do you believe that style is perpetual even when fashion trends fade?&nbsp;</span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Have you been interested in pursuing fashion designing as a career path?&nbsp;</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">So, let us focus on five steps that you need to triumph over to become a fashion designer.&nbsp;</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ol>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Complete the degree program</strong> -</span></p>
</li>
</ol>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">The first and foremost step to polish your skills as a fashion designer is getting admitted to a bachelor&rsquo;s degree in fashion design. What will you learn in this degree course?&nbsp;</span></p>
<ul>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Structure of fashion designing&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">History of fashion&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Types of fashion&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Income in this profession&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Clothing types&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">All details about the world fashion industry</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Terms of fashion design&nbsp;</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Retail management&nbsp;</span></p>
</li>
</ul>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ol start="2">
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Engage in hands-on experience</strong> -&nbsp;</span></p>
</li>
</ol>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Internships and volunteer jobs in fashion houses or for a stylist are extremely important for budding fashion designers. There are colleges to teach you but these internships will let you practice what you have been taught. You can learn and unlearn the theories of fashion but a hands-on experience will let you use the theories and make a name for yourself in the competitive industry.</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ol start="3">
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Fashion is a business: learn it</strong> -&nbsp;</span></p>
</li>
</ol>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">In the jet-set world, everything is a transaction. Fashion, too, can be purchased. To be a fashion designer is not all about being creative and artistic. If you dream about owning a fashion house, then, sales and finances, marketing and business acumen are needed as well. Stress resides behind the screen of fancy and luxurious catwalks during fashion weeks.</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ol start="4">
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Building an impressive portfolio is a must</strong> -&nbsp;</span></p>
</li>
</ol>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">A portfolio is a demonstration of your honed skills and artistic sensibilities. You must include all the assignments you have done on sewing, drawing, sketching, pattern making in your degree course. It is a way to attract and impress potential clients. It is just like creating samples for content writing. You have to present a visual representation of what you can do, hence, creating a portfolio is a necessity.&nbsp;</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">&nbsp;</span></span></p>
<ol start="5">
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Stay updated with fashion trends</strong> -&nbsp;</span></p>
</li>
</ol>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">"Trendy is the last stage before tacky." &mdash;</span><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Karl Lagerfeld</span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Once you have eliminated the trend from your creation, you have invested in people's stares and snobbish criticisms. No style is irreplaceable because the preferences of people evolve all the time. The hardest thing in fashion is to stay relevant and keep up with the pace of changing trends. Keep reading fashion magazines and new fashion journals to stay afloat in the business.</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><br /><br /></span></span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>Should a fashion designer choose a niche in fashion?</strong>&nbsp;</span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Fashion is an umbrella term. The scope in the fashion industry is limitless. Since the field is so broadly divided into sub-categories, a fashion designing student needs to select his/her niche. The niches can be swimwear, men's suit, women's accessories and so much more. You can't stay adrift by pedalling on all boats. It is impossible to make a name in the industry if you try to indulge in every category of fashion.</span></p>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><br /><br /></span></span></p>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><strong>How to become a fashion designer: experts&rsquo; suggestions</strong> -&nbsp;</span></p>
<ul>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Andrew Groves - He is the director of bachelors' degree in a fashion course at the famous University of Westminster. His in-depth and expansive knowledge about fashion is hard-earned and well-deserved. According to him, a fashion designer must be aware of the demand in the market. Taking an extra step to get involved with busy fashion designers is going to help in the long run. Networking is a vital necessity. Ensure that your CV is on the table you want it to be at the precise time of internship/job openings.</span></p>
</li>
</ul>
<p><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10"><br /><br /></span></span></p>
<ul>
<li dir="ltr">
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Ann Guise - She toiled hard for fourteen years at the costume department of BBC. She also does freelance costume designing. We all see the glamour and luxury of the fashion industry but it is a constant struggle. It is not a 9-5 job. There will be times when you would need to work around the clock. It doesn&rsquo;t seem stressful but it is certainly so.&nbsp;</span></p>
</li>
</ul>
<p dir="ltr"><span id="docs-internal-guid-83b0f7f6-7fff-c1a0-bf64-961d37ce7c10">Fashion has the potential to do so much. It can visually broadcast the personality and attitude of the generation. Fashion is not about brands or mispronounced labels. It is about the vision of the fashion designer who holds the sanctum of comfortable elegance in their hands.</span></p>
                                     </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default fashionDesigner;
