import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/malaika.jpeg";
import photo from "../src/assests/image/fast-fashion.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';



class fastFashion extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Fast Fashion: A Detailed History</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>

                                    <p>&quot;Fast fashion isn't free. Someone somewhere is paying.&quot; &mdash;Lucy Siegle, journalist and author Twenty years ago, outfit shopping wasn&rsquo;t a hobby. It was a luxury. It was a privilege and took place occasionally. You would buy clothes when summer came and you had only three summer-tank tops. You would buy clothes when a new session of college would begin. This has changed and evolved profoundly over the eras. Clothes were neither cheap nor global chains dominated our street fashion stores. But this was years back. Now, fast forward to the present times, you can afford your favourite celebrity's dinner outfit, gym outfit of the day etc.</p>

<p>Suddenly after the grandiose entry of fast fashion, nearly everyone was able to buy the latest dresses worn by models during ramp-walks. How? We will discuss.</p>

<p>Now, the question is - what is fast fashion?</p>

<p>Fast fashion is the new name for cheap versions of celebrity, catwalk trendy outfits that are being sold in high street stores at a speed that satisfies the enlarging customer demands. Staying relevant in the world of digitization is very important. To do that, you need to keep up with the trend; be it of clothes or social media life. Fast fashion enables the mass to buy the latest fashion trends at a price that doesn&rsquo;t vacate the wallets. The breakneck speed at which clothes are produced is essential because one fashion trend doesn&rsquo;t stay popular throughout the lifetime. It is only a matter of time when some other design overpowers the previous one.</p>

<p>Despite the impact of fast fashion on the environment and overworked labour, people opt for it every day. Will this ever change?</p>

<p>Problems with fast fashion -  Workers are being overworked yet paid extremely low wages - Global Labor Justice&rsquo;s survey has revealed that female workers who work in the garment production factories of H&amp;M, Gap are heinously exploited. They are subjected to overtime with no increment in wages and are forced to work in awful conditions. Since the demand is high, production speed needs to be fast. Hence, workers are encountering this issue.</p>

<p>More production of clothes = more production of waste - Till a few years ago, people used to make their clothes last as long as it was humanly possible to do. But, now things have changed shapes. People have become greedy consumers. The more they get, the less they appreciate the durability. More production of clothes leads to more purchase and more purchase lead to excessive wastage.</p>

<p>Fast fashion and water - There is a byproduct that gets released from factories that engage in the production of items of fast fashion: untreated wastewater that is highly toxic for aquatic and human lives. This untreated and toxic wastewater contains lead, arsenic and mercury. The combination can pollute the seawater and eventually destroy the lives of those who live near the bank of the sea.</p>

<p>Fast fashion leads to the release of microfibers which when put into our food system's chain destroys human life (microfibers are consumed by aquatic organisms which are then eaten by fish and ultimately consumed by humans through fish). Fast fashion causes the production of greenhouse gases too.</p>

<p> &quot;It can take up to 200 tons of fresh water to dye and finish just one ton of fabric.&quot;</p>

<p>How can we transition from fast fashion to slow fashion?  In the list below, we will provide you with five ways to shift from being a fast-fashion shopper to a slow fashion shopper.</p>

<p>There are likely chances that your new outfit bought from a fast-fashion brand will ultimately find a home in a landfill. The alternative way to prevent this 'rent a new cloth of high quality at a low price'. It has become popular. Organize a cloth swapping event. Swap your clothes for something new at a nominal or no price.  If you wash your clothes more, then, their lifespan decreases. It might end up contributing to the micro-plastic problems caused by fast fashion items. So, don't over-wash your clothes. Take good care of them. Try to mend your clothes. Don&rsquo;t go shopping every time a thread comes out of your outfit. It might be a reason to buy a new dress but there is no way you should be supporting fast fashion items. Instead, you can learn sewing.  If the day comes when you are in a genuine need of purchasing a new cardigan or a new denim jacket, you must prefer ethical fashion brands instead of fast fashion brands.</p>

<p>Is fast fashion declining?  Gen-Y is smart, wise and rational. 21st-century citizens don't promote the exploitation of the environment and human hard-work, mindless consumerism destruction of our planet earth. Hence, they encourage sustainable fashion, ethical fashion. You, as a new generation, must continue to believe in reusing the clothes as much as possible. The entire fast fashion system is undergoing a revolution.</p>

<p>Which celebrities advocate ethical fashion over fast fashion?</p>

<p>Emma Watson, Harry Potter series star, made a compelling statement by proclaiming that fashion consumers have the power to change the world by choosing what to buy and what to avoid. Orsola de Castro has gone on record saying that the quality of the clothes lies in the hands and life of the person who made it. Thereby, forcing garment works to work more in poor conditions is a complete no-no. Another famous personality made an impact on fast fashion shoppers by saying that waste is not waste until you decide to go and waste it.</p>

<p>What can you, a shopper, do to prevent this situation from escalating?  You must build a capsule wardrobe. You can construct a sustainable closet for your lifetime by doing this.</p>

<p>Be practical, versatile and logical in creating your wardrobe.</p>
                                         </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default fastFashion;
