import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/malaika.jpeg";
import photo from "../src/assests/image/Meghan.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';



class meghanMarkle extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>Meghan Markle’s Favourite Skincare Product</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p> Former Suits' actress and the Duchess of Sussex, Meghan Markle has spoken on camera about how she takes care of her skin. She even said that her approach to her skin's ageing is quite different. According to the Duchess, her ability to stay active is her form of meditating therapy.</p>

<p>Meghan Markle has always had a shining glow on her face. From a red carpet appearance to a brand shoot, she has managed to make her skin look flawless and perfect from all angles. In a nutshell, Meghan Markle's skin is radiant. It screams 'perfection' loudly. Even with a drastic shift in her lifestyle post marriage to Prince Harry, she has managed to stay as one of the most natural beauty icons.</p>

<p>Since she takes such good care of her skin even in a tight schedule, we figured there would be Meghan Markle's skincare products that she uses without fail.</p>

<p>Some of Meghan Markle&rsquo;s skincare products are listed below in case you want to try out the trick to stay glowing during public appearances all the while.</p>

<p>Everyday Cleansing Wipes - It might sound simple but the actress abides by it religiously. She makes sure that there are no makeup remnants on her face when she goes to sleep. It makes her skin well-hydrated and cleanses off all the dirt.</p>

<p>Skin Serum - In her recent interview with Allure, Meghan Markle mentioned this serum from Jan Marini. She said that her skin started glowing because of this product. This is one of Meghan Markle's skincare products.</p>

<p>Eyelash Conditioner - If you want your eyelashes to grow long like Meghan Markle's has, then, you have just hit the jackpot. This advanced eyelash conditioner from RevitaLash has managed to make the Duchess obsess over this. She commented that she doesn't need false eyelashes anymore.</p>

<p>Microfinish Powder - If you want to have a natural shine on your skin (not the greasy-shine), then, you need to swear by this product that the Duchess swears by for all public meetings.</p>

<p>Hydrating Body Lotion - Skin hydration, skin exfoliation are very essential in skincare routines. Meghan Markle uses Nivea&rsquo;s skin hydrating lotion every day without fail. It makes her feel soothed and amazing.</p>

<p> Now that we have discussed five of Meghan Markle's skincare products, we will let you in on a secret, that is, Meghan Markle's favourite skincare product. It is an essential oil that we all have at home. This oil is a remedy for acne. There are plenty of anti-acne products. So, let's find out why Meghan Markle's skin relies on this essential tea tree oil.</p>

<p>Tea tree oil is a staple item for every household. Use it along with olive oil or coconut oil for better results. From DIY face masks to toners, from cleansers to body wash, this oil is a common ingredient. It is appropriate for people with oily skin.</p>

<p>&ldquo;It's not the most glamorous thing, but if you get a cut, a mosquito bite, a small breakout, no matter what it is, it's my little cure-all. It's inexpensive, it's small enough to carry on, and I bring it with me all the time&rdquo;, Meghan Markle gushed in an interview.</p>

<p>Meghan Markle's skin is dream-like. The radiance and shimmer never seem to go away. She uses this essential oil; tea tree oil to combat her skin problems. You can do it too. Let's find out some DIY skincare recipes made by this ingredient -</p>

<p>Reduce the oil on your skin by using the tea tree oil as a scrub: Take three ingredients - sugar, coconut oil and droplets of tea tree oil. Mix them well. Apply it on your skin. Then, after some time, you need to wash it off with lukewarm water. This mixture helps to remove all dead skin cells.</p>

<p>Get rid of acne by using the tea tree oil as a mask: If you are someone who has acne all over her skin, then, the tea tree oil (few drops of it) will cure it magically. It contains terpene which efficiently kills the bacteria. If you add two drops of honey on the oil and apply the mixture on your skin, then, the redness and inflammation are lessened.</p>

<p>Use the tea tree oil as a treatment for all spots on the skin: Whenever an uninvited zit pops on your skin, go ahead and dab a drop of tea tree oil on it to minimize the inflammation. This oil helps in accentuating the activity of white blood cells. If you can add a bit of turmeric and rosehip oil, then, it is even more ideal.</p>

<p>The Royal Duchess of Sussex - Meghan Markle has a hectic schedule. All she wants from skincare products is to make her look 'not living dead'. Despite being financially privileged, she doesn't seek expensive products. Her skin is refreshingly pleasant. Yet that is not the end-result of applying products that cost in quadruple digits. Her choices are affordable and relatable. So, why fuss over your skin problems when this inexpensive tea tree oil (that is Meghan Markle's favourite skincare product) can solve all of it?</p>


                                         </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default meghanMarkle;
