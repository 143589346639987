import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import pic from "../src/assests/image/malaika.jpeg";
import photo from "../src/assests/image/gummy.jpg"
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import image from '../src/assests/image/1.png';
import Header from './header.js';



class Gummy extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>One Gummy, Benefits Many</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <div>Hello everyone&nbsp;</div>
<div>Today I'll be sharing about an incredible product that I recently discovered.&nbsp;</div>
<div>During this pandemic, I was looking for a product that can help boost my immunity, So I came across Hea and one of its Immunity Boosters products.&nbsp;</div>
<div>I was curious enough to order one and test them for myself. Working on immunity had become the goal for many people in recent times, and I genuinely feel we should pay attention to what we consume.</div>
<div>Building immunity not only helps to protect us from viruses but also other health issues.</div>
<div>It was my first time trying a product specifically for immunity. Before this, I only relied on traditional Indian recipes, like Kadha.&nbsp;</div>
<div>I love Kadha, but it also has a limitation; there are times when I'm not able to get fresh ingredients, and it is a whole lot of work for someone like me who is staying away from family.</div>
<div>&nbsp;</div>
<div>Having an over-the-counter product bridges this gap and helps to constantly consume essential vitamins like vitamins C, D, A &amp; Zinc.</div>
<div>Hea Immunity Boosters are individually packaged delicious gummies that are simple to carry and take. So that you never run out of energy to do anything you want to do, Hea Immunity Boosters are available in an easy-to-consume format. They come in bite-sized doses&mdash;convenient to carry, which can be consumed on the go.</div>
<div>&nbsp;</div>
<div>It helps to boost immunity with Vitamin C and Zinc and Vitamin A and Vitamin D in tasty Fresh Orange flavor. These gummies are free of added sugar, artificial colors, flavors and preservatives, soy, dairy, and GMO and use high-quality, tasty ingredients.</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>Hea Boosters are created for today's women and today's moms.&nbsp;</div>
<div>It is possible to take these without interruption at work, the office, or even while traveling.&nbsp;</div>
<div>The gummies are packaged individually for convenience and can be consumed on the go at any time.&nbsp;</div>
<div>&nbsp;</div>
<div>Since life never stops, and to keep going, you must maintain your aspirations, goals, and immunity!</div>
<div>Let this power-packed formula help you keep your internal system running strong so that you live to your fullest.</div>
<div>&nbsp;</div>
<div>Little About The Product:</div>
<div>Hea Immunity Boosters in Fresh Orange Flavor is a health supplement in the shape of a delicious gummy. It contains the perfect combination of Vitamin C, Zinc, Vitamin D, and Vitamin A, both of which serve to improve the immune system.&nbsp;</div>
<div>Vitamin C provides antioxidant protection, and zinc, combined with vitamin C, helps in T-cell activity, which improves immune defenses and strengthens the body to better combat infection and viruses.</div>
<div>Vitamin C also supports iron absorption from food sources.&nbsp;</div>
<div>Vitamin C is a water-soluble vitamin that does not accumulate in the body. As a result, consuming adequate amounts of vitamin C daily by proper diet and supplementation is essential. These pectin-based gummies are entirely vegetarian, easy to digest, and do not induce digestive or gastric pain.</div>
<div>&nbsp;</div>
<div>How To Consume:</div>
<div>Chew 1-2 gummies per day anytime during the day. No food or water is needed.&nbsp;</div>
<div>Daily consumption of these gummies helps support a strong and healthy immune system.</div>
<div>Chew it thoroughly before swallowing it.</div>

<div>Important Links:</div>
<p><a href="https://www.heaboosters.com/products/hea-maximum-energy-boosters">Click Here!</a> To try it for yourself. </p>

<p>For all the latest updates and information, don't miss out on Hea Boosters Instagram handle. <a href="https://www.instagram.com/hea.boosters/">Click Here!</a></p>
                                         </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default Gummy;
