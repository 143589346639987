import React, { Component } from 'react';
import { Row, Col, Grid, Card } from 'react-bootstrap';
import './App.css';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import photo from '../src/assests/image/Keratin.png';
import mask from '../src/assests/image/Mask.jpg';
import shampoo from '../src/assests/image/Shampoo.jpg';
import Header from './header.js';



class keratinHair extends Component {


    handleClcik = () => {
        console.log("working")
    }

    render() {

        return (
            <div className='App'>

                <Header />
                <div >
                    <div className='Carouselsetting container '>

                        {/* <Carousel>
  <Carousel.Item> */}

                        <Card style={{ "marginTop": "50px", "border": "0" }}>
                            <Card.Img variant='top' src={photo} >

                            </Card.Img>
                            <Card.Title style={{ "marginTop": "50px" }}>
                                <h3 >
                                    <b>How To Repair Damaged Hair Naturally || Keratin Hair Mask At Home</b>
                                </h3>

                            </Card.Title>

                            <Card.Subtitle style={{ "marginTop": "20px", "textAlign": "left", "marginLeft": "20px" }}>
                            
     </Card.Subtitle>
                            <Card style={{ "border": "0" }}>
                                <Card.Body>
                                    <Card.Text style={{ "textAlign": "left" }}>
                                    <p>Keratin! Keratin! Keratin!</p>
<p>You might have heard of keratin a lot of the time. How can you do Keratin treatment at home? What is the Best keratin hair mask in India? How to do a hair spa at home naturally? If you have these questions, then follow along and you&apos;ll get an answer for everything.&nbsp;</p>
<p></p>
<p>So precisely what is keratin?</p>
<p>Keratin is a group of proteins that make up the structure of our hair, nails, and skin. Keratin is an essential protein found in our hair that is produced naturally. It protects and maintains the hair&apos;s health by acting as an exterior protective and inside the structural protein. The keratin in the hair is lost due to exposure to external elements such as sun damage, pollution, chemicals, and changes in your lifestyle. As a result, you get dry, damaged, and dull hair.</p>
<p></p>
<p>Why do I need keratin? For smoother hair?</p>
<p>If you are looking for more manageable, smooth, fuss-free hair, you must opt for keratin treatment. During the process, keratin is artificially added to the hair to make it look smooth, shiny, and frizz-free. A keratin treatment is basically rebuilding the hair by putting the lost protein into your strands.&nbsp;</p>
<p>But going for regular keratin treatment can be very expensive as the typical cost of the treatment is INR 6,000 and above. Ideally, it&apos;s less for short-length hair, and the price range increases for longer hair.</p>
<p>But there are products like&nbsp;<a href="https://www.vasustore.com/trichup-keratin-hair-care-kit-salon-like-hair-spa-at-home" target="_blank">Trichup Keratin Shampoo &amp; Hair Mask</a> that make it a lot easier to do this treatment at home and get you salon-like hair at home.</p>
<p></p>
<p>I&apos;m here to tell you about my experience with easy-to-follow keratin hair care for your frizzy hair with&nbsp;<a href="https://www.vasustore.com/trichup-keratin-hair-care-kit-salon-like-hair-spa-at-home" target="_blank">Trichup Keratin Shampoo &amp; Hair Mask</a> and how you have to use it to get the Salon like hair at home with Trichup.&nbsp;</p>
<p></p>
<p>Step 1: Wet Your Hair</p>
<p>Start by wetting your hair thoroughly and running your fingers through it to ensure it&apos;s completely wet. Use lukewarm water. It helps open the cuticles, making it easier for your hair to absorb your keratin mask later.</p>
<p></p>
<p>Step 2: Use Trichup Keratin Shampoo</p>
<p>Trichup Keratin Shampoo contains Keratin protein, which forms a protective layer over the hair, replacing lost keratin and restoring strength, flexibility, and reducing breakage. One of the best keratin shampoos in India.&nbsp;</p>
<p>It also includes appropriate conditioners to keep hair healthy, lustrous, and frizz-free. Mix the keratin shampoo with a bit of water before applying the shampoo to your hair. Doing this will reduce the direct impact of the shampoo on your scalp and minimize product consumption. Then, make sure to cover your entire scalp and crown with shampoo. The truth is that you should concentrate on shampooing your scalp rather than your hair&apos;s ends. Apply shampoo solely to the scalp, then rinse it to clean the hair.</p>
<p></p>
<p>Step 3: Go Easy On The Scalp</p>
<p>Our moms advised us not to comb when the hair was wet, and there was a reason for it. Our hair tends to break when it is wet. Never scrub with your nails since it might cause severe discomfort and even hair breakage. Instead, massage your scalp with light pressure using your fingertips and palms softly.</p>
<p>While massaging the shampoo into your scalp, avoid circular or forceful back and forth movements.&nbsp;</p>
<p></p>
<p>Step 4: Rinse</p>
<p>Rinse your hair for 1 - 2 minutes under running water to remove any shampoo residue. One of the most common mistakes people make is leaving shampoo in their hair, which can cause scalp buildup. Use cold water to keep them more robust and healthier if you have dry hair. Coldwater does not dry out your hair&apos;s sebum layer, a naturally lubricated barrier that protects and strengthens the hair.</p>
<p></p>
<p>Step 5: Damaged Hair Repair at Home With Trichup</p>
<p>Squeeze out the water from hair gently but leave some moisture so hair can easily absorb the keratin treatment. Coat your hair strands with the keratin hair mask treatment, starting from the roots to the tips. Stop at 1 inch from the scalp. Gently massage your hair with the product. This process will help repair damaged hair at home.&nbsp;</p>
<p></p>
<p></p>
<p>Step 6: Leave It For 15 Minutes</p>
<p>Comb your hair gently with a wide-tooth comb while the product is still on your hair. Allow 15 minutes for the treatment to sit on your hair. If possible, wrap your hair in a hot towel.</p>
<p></p>
<p>Step 7: Wait</p>
<p>Keep dry hair and wait for 48 hours before washing. This gives the keratin treatment more time to seep into your hair and work its magic. Make sure you don&apos;t tuck your hair behind your ears or tie it. Just keep it down and straight.</p>
<p></p>
<p>Step 8: Dry&nbsp;</p>
<p>Let your hair dry naturally. Do not rub your hair vigorously. This will create friction, and you&apos;ll have frizz in the hair. If possible, dry your hair with a microfiber towel or an old T-shirt.&nbsp;</p>
<p></p>
<p>Steo 9: Style and Flaunt</p>
<p>And that&apos;s all these were easy-to-do steps that you can regularly perform to get silky, smooth hair at home. This is one of the best keratin treatment products in India that I came across that works wonders. &nbsp;</p>
<p></p>
<p></p>
<p>Sounds Amazing; where can I get these products?</p>
<p></p>
<p>Hair Care Combo</p>
<p><a href="https://www.vasustore.com/hair-care/damaged-hair" target="_blank">https://www.vasustore.com/hair-care/damaged-hair</a></p>
<p></p>
<img  variant='top' src={shampoo} width="500" height="600">

</img>
<p>Shampoo</p>
<p><a href="https://www.vasustore.com/trichup-keratin-hair-shampoo-for-damaged-hair-repair" target="_blank">https://www.vasustore.com/trichup-keratin-hair</a></p>

<p></p>
<img  variant='top' src={mask} width="500" height="600" ></img>
<p>Hair Mask</p>
<p>-<a href="https://www.vasustore.com/trichup-keratin-hot-oil-treatment-hair-mask-hair-spa-at-home" target="_blank">https://www.vasustore.com/trichup-keratin-hot-oil</a></p>
<p></p>
<p>To read more about beauty</p>
<p>Blog page-<a href="https://www.vasuhealthcare.com/blogs/" target="_blank">https://www.vasuhealthcare.com/blogs/</a></p>
<p></p>
<p>Get a 10% Discount on using the code - KERATIN10.</p>
<p></p>
<p>Don&apos;t miss out on Trichup India&apos;s Instagram handle (@trichupindia).&nbsp;<a href="https://www.instagram.com/trichup_india/" target="_blank">Click Here</a>&nbsp;</p>

                                     </Card.Text>

                                </Card.Body>

                            </Card>



                        </Card>



                    </div>
                    {/* <CarouselTitle /> */}



                </div>

            </div>


        );
    }
}

export default keratinHair;
