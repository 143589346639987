import React, { Component } from 'react';
import './App.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Kofol from "./assests/image/KofolBanner.png"
import photos from "../src/assests/article/fashionBanner.png"
import shein from "../src/assests/article/shein-863.jpg"
import Meghan from "../src/assests/image/Meghan.jpg"
import { Row, Col,Card ,Button,Container} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import fastfashion from "../src/assests/image/fast-fashion.jpg"
import Sesa from "../src/assests/image/Sesa_Header.png"
import Gummy from "../src/assests/image/gummy.jpg"
import Keratin from "../src/assests/image/Keratin.png"
class CarouselTitle extends Component {
  handleClcik=()=>{

    this.props.history.push({
      pathname:'/fashiontrends'
    })
   }
   handleClcik1=()=>{
  
    this.props.history.push({
      pathname:'/Shein'
    })
   }
   handleClcik2=()=>{
 
    this.props.history.push({
      pathname:'/MalaikaSkincare'
    })
   }
   handleClcik3=()=>{
 
    this.props.history.push({
      pathname:'/MeghanSkincare'
    })
   }
   handleClcik4=()=>{
 
    this.props.history.push({
      pathname:'/fastFashion'
    })
   }
   handleSesa=()=>{
 
    this.props.history.push({
      pathname:'/Sesa'
    })
   }
   
   handleHea=()=>{
 
    this.props.history.push({
      pathname:'/hea'
    })
   }
   handleClickKofol=()=>{
    this.props.history.push({
      pathname:'/Kofol'
    })
   }
   handleClickKeratin=()=>{
    this.props.history.push({
      pathname:'/Keratin'
    })
   }


    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5,
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          };
        return(
            <div style={{padding: "10px", border: "none", margin:"5px"}}>
                <Carousel   responsive={responsive}>
                <div> 
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img  onClick={() => this.handleClickKeratin()} variant="top" src={Keratin} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    How To Repair Damaged Hair Naturally || Keratin Hair Mask At Home
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
                <div> 
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img  onClick={() => this.handleClickKofol()} variant="top" src={Kofol} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    4 Best Ayurvedic Cough &amp; Cold Products That Actually Works !
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
  <div> 
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img  onClick={() => this.handleClcik()} variant="top" src={photos} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    2020 Fashion Trends That You Must Try
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
  <div> 
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img  onClick={() => this.handleSesa()} variant="top" src={Sesa} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title style={{marginLeft:"40%"}}>
    Sesa Ayurveda
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
  <div  >
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img variant="top" onClick={() => this.handleHea()}src={Gummy} style={{ width:"500px",height:"350px"}} />
  <Card.Body>
   
    <Card.Title style={{marginLeft:"25%"}}>
    One Gummy, Benefits Many 
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
 
  <div  >
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img variant="top" onClick={() => this.handleClcik1()}src={shein} style={{ width:"500px",height:"350px"}} />
  <Card.Body>
   
    <Card.Title>
    Top 8 SHEIN Alternatives That You Must Try! 
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
  {/* <div>
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img variant="top" onClick={() => this.handleClcik3()} src={Meghan} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    Meghan Markle’s Favourite Skincare Product 
    </Card.Title>
    
  </Card.Body>
</Card>
  </div> */}
  <div>
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img variant="top" onClick={() => this.handleClcik4()}  src={fastfashion} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    Fast Fashion: A Detailed History 
    </Card.Title>
    
  </Card.Body>
</Card>
  </div>
  {/* <div>
  <Card className="d-block w-100" style={{padding: "10px", border: "none"}}>
  <Card.Img  variant="top" onClick={() => this.handleClcik2()} src={malaika} style={{ width:"500px",height:"350px"}}/>
  <Card.Body>
   
    <Card.Title>
    Malaika Arora Swears by this Skincare Ingredient (Beauty)
    </Card.Title>
    
  </Card.Body>
</Card>
  </div> */}
</Carousel>
            </div>

        );

    }
}
export default withRouter(CarouselTitle);
